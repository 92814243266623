import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import Axios from 'axios';

import Title from '../Title';

// Source: https://blog.bitsrc.io/email-confirmation-with-react-257e5d9de725

export default function UserActivation() {
  const { emailToken } = useParams();
  const [updateUser, setUpdateUser] = useState({});
  const [updated, setUpdated] = useState(false);
  const [errorRes, setErrorRes] = useState(null);

  useEffect(() => {
    async function fetchUser() {
      try {
        const doc = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/getUserByToken',
          {
            params: { userToken: emailToken },
          }
        );
        if (doc.status === 200) {
          console.log(doc);
          setUpdateUser({
            email: doc.data.email,
            emailToken: emailToken,
          });
        }
      } catch (error) {
        console.log(error.response);
        setErrorRes(error.response.data);
      }
    }
    fetchUser();
  }, [emailToken]);

  async function activateUser(event) {
    event.preventDefault();
    try {
      const doc = await Axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/activate',
        {
          email: updateUser.email,
          userToken: updateUser.emailToken,
        }
      );
      console.log(doc);
      if (doc.status === 200) {
        setUpdated(true);
      }
    } catch (error) {
      console.log(error.response);
      setErrorRes(error.response.data);
    }
  }

  return (
    <>
      {errorRes ? (
        <div className='text-center'>
          <Title title='Activate User' />
          <h3>Error: {errorRes} </h3>
          <Link className='btn btn-warning' to='/'>
            Return
          </Link>
        </div>
      ) : (
        <div>
          {updated ? (
            <>
              <Title title='Activate User Success' />
              <div className='text-center'>
                <h5>Your account has been successfully activated.</h5>
                <h5>Please login.</h5>
              </div>
            </>
          ) : (
            <div className='text-center'>
              <Title title='Activate User' />
              <h5>Activate Account: {updateUser.email}</h5>
              <button className='btn btn-warning' onClick={activateUser}>
                Activate
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
}
