import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";

export default function EnrolFormCalendarModal() {
  const [show, setShow] = useState(false);

  return (
    <>
      <button className="btn btn-link" onClick={() => setShow(true)}>
        <img src="/img/infart-calendar-2024.jpg" width="100%" alt="calendar" />
      </button>

      <Modal
        className="text-dark"
        show={show}
        size="lg"
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Calendar</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <embed
            src="/img/infart-calendar-2024.pdf"
            width="100%"
            height="500vh"
            alt="calendar"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
