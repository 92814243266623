import React, { useContext } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';

import authContext from '../../context/authContext';
// import bgVideo from 'home_bgVideo_Nebula.mp4';
import Header from '../Header';
import './Home.css';

export default function Home() {
  const { langEn } = useContext(authContext);

  return (
    <div className='substitudeBG'>
      <video autoPlay loop muted playsInline>
        <source src='/img/home_bgVideo_Nebula.mp4' type='video/mp4' />
      </video>
      {/* <div className='vheader'>
        <Header />
      </div> */}

      <Header />
      <div className='d-none d-md-block text-white'>
        <div className='position-relative text-center font-weight-bolder pt-5'>
          {langEn ? (
            <h3>High Quality Art Education</h3>
          ) : (
            <h3>高质量的美术教育</h3>
          )}
        </div>
        <div className='position-relative text-center font-weight-bolder pb-5'>
          {langEn ? (
            <h5>
              Oil Painting | Pencil Sketch | Watercolor | Colour Pencil | Oil
              Pastel | Manga | Handcraft
            </h5>
          ) : (
            <h5>油画 | 铅笔素描 | 水彩画 | 彩色铅笔 | 油粉彩 | 漫画 | 手工</h5>
          )}
        </div>
      </div>

      <div className='container d-md-none text-white'>
        <div className='position-relative text-center font-weight-bolder pt-5'>
          {langEn ? <b>High Quality Art Education</b> : <b>高质量的美术教育</b>}
        </div>
        <small className='position-relative text-center font-weight-bolder pb-5'>
          {langEn ? (
            <p>
              Oil Painting | Pencil Sketch | Watercolor | Colour Pencil | Oil
              Pastel | Manga | Handcraft
            </p>
          ) : (
            <p>油画 | 铅笔素描 | 水彩画 | 彩色铅笔 | 油粉彩 | 漫画 | 手工</p>
          )}
        </small>
      </div>

      <div className='container p-3'>
        <div className='position-relative text-center'>
          <Carousel interval={3000}>
            <Carousel.Item>
              <Link to='/enrolform'>
                <img
                  className='d-block w-100'
                  src='/img/carousel_enrol.jpg'
                  alt='First slide'
                />
                <Carousel.Caption>
                  <div className='d-none d-md-block'>
                    <h3>{langEn ? 'Enrol' : '注册'}</h3>
                    <h5>Enrol art classes</h5>
                  </div>
                  <div className='d-md-none'>
                    <h5>{langEn ? 'Enrol' : '注册'}</h5>
                    <small>Enrol art classes</small>
                  </div>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>

            <Carousel.Item>
              <Link to='/gallery/show'>
                <img
                  className='d-block w-100'
                  src='/img/carousel_gallery.jpg'
                  // height='500px'
                  alt='second slide'
                />
                <Carousel.Caption>
                  <div className='d-none d-md-block'>
                    <h3>{langEn ? 'Art Gallery' : '画廊'}</h3>
                    <h5>Student Artworks.</h5>
                  </div>
                  <div className='d-md-none'>
                    <h5>{langEn ? 'Art Gallery' : '画廊'}</h5>
                    <small>Student Artworks.</small>
                  </div>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>

            <Carousel.Item>
              <Link to='/products/all'>
                <img
                  className='d-block w-100'
                  src='/img/carousel_shop.jpg'
                  // height='500px'
                  alt='Third slide'
                />
                <Carousel.Caption>
                  <div className='d-none d-md-block'>
                    <h3>{langEn ? 'Shop' : '商店'}</h3>
                    <h5>Art Supplies.</h5>
                  </div>
                  <div className='d-md-none'>
                    <h5>{langEn ? 'Shop' : '商店'}</h5>
                    <small>Art Supplies.</small>
                  </div>
                </Carousel.Caption>
              </Link>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
