import { createContext } from 'react';

const productContext = createContext({
  cart: [],
  catList: [],
  subCats: [],
  products: [],
  onShelfProducts: [],
  categories: [],
  isLoading: false,
  addToCart: () => {},
  removeFromCart: () => {},
  deleteProductFromCart: () => {},
  clearCart: () => {},
});

export default productContext;
