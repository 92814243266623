import React from "react";

export default function EnrolFormTermCond() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h3>Terms and Conditons</h3>
        </div>
        <div className="form-group col-md-4">
          <h5>About Fee</h5>
          <ul>
            <li>Trial lesson is $50 and no need to bring any material.</li>
            {/* <li>
                      Normal rate of Infinity Art School fee is applied after
                      early bird due date.
                    </li> */}
            <li>
              Normal rate of Infinity Art School fee is applied without Voucher.
            </li>
            <li>
              Infinity Art School fee is charged based on term, not class and
              the minimum payment is one term. No refund will be given after two
              weeks of the term.
            </li>
            <li>
              Application for next t erm starts from week 7 of current term.
            </li>
            <li>
              If you make any payment by cash, please ask for the receipt and
              keep it.
            </li>
          </ul>
        </div>
        <div className="form-group col-md-4">
          <h5>Timetable and Safety</h5>
          <ul>
            <li>
              Please follow Infinity Art School schedule, do not be late or come
              too early.
            </li>
            <li>
              If you are late, please keep in mind that other students are
              having a class. Keep your voice down and try not to enter the
              classroom.
            </li>
            <li>
              Please pick up the students on time. Please knock and wait the
              teacher to open the door.
            </li>
            <li>
              Please note any student older than 12yr can go home inde pendently
              but must inform t he t eacher first.
            </li>
            <li>
              You are responsible for your own safety after you leave Infinity
              Art School.
            </li>
          </ul>
        </div>
        <div className="form-group col-md-4">
          <h5>About Makeup Class</h5>
          <ul>
            <li>
              Makeup class can only be attended during the term It cannot be
              saved for the next term.
            </li>
            <li>Each student may have up to two makeup classes each term.</li>
            <li>There will be no makeup class fo r holiday program.</li>
            <li>
              Makeup class can only be chosen from class times given by the
              teacher.
            </li>
          </ul>
        </div>
        <div className="col-12 text-center">
          <small>
            <sup>*</sup>
            <i>
              Infinity Art researve the right for final explanation of the above
              clauses.
            </i>
          </small>
        </div>
      </div>
    </div>
  );
}
