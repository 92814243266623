import React from 'react';

export default function EnrolFormUSer({ enrolUser, setEnrolUser }) {
  const handleUserChange = (event) => {
    const { name, value } = event.target;
    setEnrolUser((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setEnrolUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  return (
    <div className='form-row'>
      <div className='form-group required col-md-4'>
        <label htmlFor='pName' className='control-label'>
          Parent Name
        </label>
        <input
          type='text'
          id='pName'
          className='form-control'
          required
          name='name'
          value={enrolUser.name}
          onChange={handleUserChange}
        />
      </div>
      <div className='form-group required col-md-4'>
        <label htmlFor='email' className='control-label'>
          <span role='img' aria-label='email'>
            📧
          </span>{' '}
          Parent Email
        </label>
        <input
          type='email'
          id='email'
          required
          className='form-control'
          name='email'
          value={enrolUser.email}
          onChange={handleUserChange}
        />
      </div>
      <div className='form-group required col-md-4'>
        <label htmlFor='phone' className='control-label'>
          ☎︎ Contact Number
        </label>
        <input
          className='form-control'
          id='phone'
          type='text'
          required
          name='phone'
          value={enrolUser.phone}
          pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
          onChange={handleUserChange}
        />
      </div>

      <div className='form-group col-md-4'>
        <label htmlFor='inputAddress'>Address</label>
        <input
          type='text'
          className='form-control'
          id='inputAddress'
          name='line1'
          value={enrolUser.address.line1}
          onChange={handleStreetInput}
        />
      </div>
      <div className='form-group col-md-4'>
        <label htmlFor='inputCity'>Suburb</label>
        <input
          type='text'
          className='form-control'
          id='inputCity'
          name='suburb'
          value={enrolUser.address.suburb}
          onChange={handleStreetInput}
        />
      </div>
      <div className='form-group col-md-2'>
        <label htmlFor='inputState'>State</label>
        <select
          id='inputState'
          className='form-control'
          name='state'
          defaultValue='NSW'
          onChange={handleStreetInput}
        >
          <option value='NSW'>NSW</option>
        </select>
      </div>
      <div className='form-group col-md-2'>
        <label htmlFor='inputZip'>Post Code</label>
        <input
          type='text'
          className='form-control'
          id='inputZip'
          name='postcode'
          value={enrolUser.address.postcode}
          onChange={handleStreetInput}
        />
      </div>
    </div>
  );
}
