import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function EnrolOrderItem({ index, enrolOrder, setEdit }) {
  const {
    _id,
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    payInfo,
    receipt,
    // submitDate,
    payDate,
    confirm,
    note,
  } = enrolOrder;
  const { token } = useContext(authContext);

  async function deleteOder(productId) {
    const authStr = 'Bearer ' + token;
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/enrolOrders/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-1' : 'row mt-1 bg-white'}>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>Student/DoB:Gender </span>
          <div>{student}</div>
          <div>
            {DoB}:{gender}
          </div>
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>parentEmail/parentPhone </span>
          <div>{parentEmail}</div>
          <div>{parentPhone}</div>
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>selSlots: </span>
          {selSlots.map((slot, index) => (
            <div key={index}>{slot.substr(0, slot.indexOf('-'))}-</div>
          ))}
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>payAmount, payMethod / payInfo: </span>
          <div>
            ${payAmount}, {payMethod}
          </div>{' '}
          <div>{payInfo}</div>
        </div>

        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>payAmount via payMethod / payInfo: </span>
          #{receipt}
          <div>{note}</div>
        </div>

        <div className='col-11 col-md-1'>
          <span className='d-lg-none'>payAmount via payMethod / payInfo: </span>
          <input type='checkbox' checked={confirm} disabled />
          <div>{payDate && new Date(payDate).toLocaleDateString()}</div>
        </div>

        <div className='col-11 col-md-1'>
          <button className='btn btn-sm btn-link' onClick={() => setEdit(_id)}>
            <i className='fas fa-edit' />
          </button>
          <button
            className='btn btn-sm btn-link'
            onClick={() => deleteOder(_id)}
            // disabled
          >
            <i className='fas fa-trash-alt' />
          </button>
        </div>

        {/* <div className='col-11 col-md-2 text-right'>Booking Date:</div>
        <div className='col-11 col-md-2'>
          {submitDate ? new Date(submitDate).toString().substr(0, 21) : ''}
        </div>

        <div className='col-11 col-md-2 text-right'>Confirm Date:</div>
        <div className='col-11 col-md-2'>
          {payDate ? new Date(payDate).toString().substr(0, 21) : ''}
        </div> */}
      </div>
    </>
  );
}
