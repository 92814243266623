import React, { useContext } from 'react';
import { useParams, Link } from 'react-router-dom';

import productContext from '../../context/productContext';
import authContext from '../../context/authContext';

function ProductDetails() {
  const { productId } = useParams();
  const { products, addToCart } = useContext(productContext);
  const { langEn } = useContext(authContext);

  const product = products.find((item) => item._id === productId);
  if (!product) return <></>; // skip the first round of render, where products are not yet loaded.

  const {
    name,
    cnName,
    img,
    brand,
    cnBrand,
    unit,
    cnUnit,
    price,
    includeGST,
    info,
    infoCN,
    shelf_pos,
  } = product;

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-10 mx-auto text-center my-3'>
          <h1>{langEn ? name : cnName}</h1>
        </div>
      </div>
      <div className='row'>
        <div className='col-10 mx-auto col-md-6 my-3'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + img}
            className='img-fluid'
            alt='product'
          />
        </div>
        <div className='col-10 mx-auto col-md-6 my-3'>
          <h2>
            {langEn ? 'Name' : '名称'}: {langEn ? name : cnName}
          </h2>
          <h4 className='text-title text-uppercase text-muted mt-3 mb-2'>
            {langEn ? 'Brand' : '品牌'}: {langEn ? brand : cnBrand}
          </h4>
          <h5>
            Price: ${price} per {langEn ? unit : cnUnit}
            {includeGST && ',  include GST $' + (price / 11).toFixed(2)}
          </h5>
          <p className='text-muted lead'>
            Shelf Position: aisle-{shelf_pos.split('.')[0]}.shelf-
            {shelf_pos.split('.')[1]}.level-{shelf_pos.split('.')[2]}
          </p>
          <p className='text-capitalize font-weight-bold mt-3 mb-0'>
            {langEn ? info : infoCN}
          </p>
          {/* buttons */}
          <div>
            <Link to='/products/all'>
              <button
                className='btn btn-outline-primary px-2 py-1 mx-2'
                style={{ fontSize: '1.2rem', borderWidth: 'medium' }}
              >
                Back to Shop <i className='fas fa-store'></i>
              </button>
            </Link>

            <button
              className='btn btn-outline-success px-2 py-1'
              style={{ fontSize: '1.2rem', borderWidth: 'medium' }}
              onClick={() => addToCart(productId)}
            >
              Add to Cart<i className='fas fa-cart-plus'></i>
            </button>
            <Link to='/cart'>
              <button
                className='btn btn-outline-success px-2 py-1 mx-2'
                style={{ fontSize: '1.2rem', borderWidth: 'medium' }}
              >
                Go to Cart<i className='fas fa-shopping-cart'></i>
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
