import React from 'react';
import MyEnrolOrderItemModal from './MyEnrolOrderItemModal';

export default function MyEnrolOrderItem({ index, enrolOrder }) {
  const {
    // _id,
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    payInfo,
    receipt,
    confirm,
    // submitDate,
    payDate,
    note,
  } = enrolOrder;

  return (
    <>
      <div className={index % 2 ? 'row mt-1' : 'row mt-1 bg-white'}>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>Student/DoB:Gender </span>
          <div>{student}</div>
          <div>
            {DoB}:{gender}
          </div>
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>parentEmail/parentPhone </span>
          <div>{parentEmail}</div>
          <div>{parentPhone}</div>
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>selSlots: </span>
          {selSlots.map((slot, index) => (
            <div key={index}>{slot.substr(0, slot.indexOf('-'))}-</div>
          ))}
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>payAmount, payMethod / payInfo: </span>
          <div>
            ${payAmount}, {payMethod}
          </div>{' '}
          <div>{payInfo}</div>
        </div>

        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>payAmount via payMethod / payInfo: </span>
          #{receipt}
          <div>{note}</div>
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>paid: </span>
          <input type='checkbox' checked={confirm} disabled />
          <div>{payDate && new Date(payDate).toLocaleDateString()}</div>
        </div>

        <div className='col-11 col-lg-1'>
          <MyEnrolOrderItemModal enrolOrder={enrolOrder} />
        </div>
      </div>
    </>
  );
}
