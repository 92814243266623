import React from "react";

import EnrolFormCalendarModal from "./EnrolFormCalendarModal";

export default function EnrolFormCalendar() {
  return (
    <div className="col-md-4">
      <b> Calendar:</b>
      <EnrolFormCalendarModal />
      <br />
      <b>Holiday Program - $220</b>
      <p>
        <i>One class each day for four days.</i>
      </p>
      <b>Art Kit for New Student - $150</b>
      <p>
        <i>
          Includes: an art portfolio bag, watercolour pencils, a sketch book,
          acrylic colour, a display folder & oil pastels.
        </i>
      </p>
      <div>
        <b>
          Alternatively,{" "}
          <button className="btn btn-primary" onClick={() => window.print()}>
            Print
          </button>{" "}
          this form
        </b>
        <br /> and bring it to:
        <br />
        <ul style={{ listStyleType: "none" }}>
          <li>INFINITY ART SCHOOL</li>
          <li>2/120A Rowe Street, Eastwood</li>
        </ul>
      </div>
    </div>
  );
}
