import React, { useState, useEffect, useCallback } from 'react';

import authContext from './authContext';

var logoutTimer;
const LOGIN_TOKEN_DURATION = 3600 * 1000 * 10; // 10 hours

const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [tokenExpireDate, setTokenExpireDate] = useState();
  const [userId, setUserId] = useState();
  const [userName, setUserName] = useState('');
  const [admin, setAdmin] = useState(false);

  const [langEn, setLangEn] = useState(true);

  const setLang = () => {
    localStorage.setItem(process.env.REACT_APP_LS_LANGEN, !langEn);
    setLangEn(!langEn);
  };

  useEffect(() => {
    const _lang = localStorage.getItem(process.env.REACT_APP_LS_LANGEN);
    _lang && setLangEn(_lang === 'true');
  }, []);

  const login = useCallback((_uId, _token, _uName, _admin, _expDate) => {
    setToken(_token);
    setUserId(_uId);
    setUserName(_uName);
    setAdmin(_admin);
    const _tokenExpDate =
      _expDate || new Date(new Date().getTime() + LOGIN_TOKEN_DURATION);
    setTokenExpireDate(_tokenExpDate);
    // if login first time (_expDate NOT provided), _tokenExpDate is set to new Date()+1hr
    // if login from useEffect reload (_expDate provided), tokenExpDate is set to _expDate.

    localStorage.setItem(
      process.env.REACT_APP_LS_TOKEN,
      JSON.stringify({
        userId: _uId,
        token: _token,
        userName: _uName,
        admin: _admin,
        expiration: _tokenExpDate.toISOString(),
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpireDate(null);
    setUserId(null);
    localStorage.removeItem(process.env.REACT_APP_LS_TOKEN);
  }, []);

  useEffect(() => {
    if (token && tokenExpireDate) {
      const remainingTime = tokenExpireDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      // logout();
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpireDate]);

  useEffect(() => {
    const storedData = JSON.parse(
      localStorage.getItem(process.env.REACT_APP_LS_TOKEN)
    );
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        storedData.userName,
        storedData.admin,
        new Date(storedData.expiration)
      );
    } else if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) < new Date()
    ) {
      logout();
    }
  }, [login, logout]);

  return (
    <authContext.Provider
      value={{
        langEn,
        setLang,
        token,
        userId,
        userName,
        admin,
        login,
        logout,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthProvider;
