import React from 'react';

export default function ProductManageColumns() {
  return (
    <div className='d-none d-lg-block'>
      <div className='row'>
        <div className='col-11 col-lg-1'>slotID</div>
        <div className='col-11 col-lg-3'>slotName</div>
        <div className='col-11 col-lg-1'>active</div>
        <div className='col-11 col-lg-2'>save/edit</div>
      </div>
    </div>
  );
}
