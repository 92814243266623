import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import OrderItem from './OrderItem';

export default function OrdersByUserModal({ userId, token }) {
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchOrders = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + `/orders/uId/${userId}`,
          {
            headers: { Authorization: authStr },
          }
        );

        setOrders([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    if (!!token) {
      fetchOrders();
    }
  }, [token, userId]);

  return (
    <>
      <button className='btn btn-link' onClick={() => setShowModal(true)}>
        <i className='fas fa-receipt'></i>
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {orders[0] &&
              'Orders by ' +
                orders[0].owner.fname +
                ' ' +
                orders[0].owner.lname}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid mt-3'>
            <div className='row'>
              {orders.map((item) => {
                return (
                  <OrderItem
                    key={item._id}
                    title={'Order'}
                    orderId={item._id}
                    paidDate={item.paidDate}
                    paymentId={item.paymentId}
                    deliveredDate={item.deliveredDate}
                    productList={item.productList}
                  />
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
