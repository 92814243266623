import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function EnrolDatePrice({ enrolDP }) {
  const {
    _id,
    earlyBirdDate,
    normalPrice1T,
    earlyBird1T,
    normalPrice2T,
    earlyBird2T,
  } = enrolDP;

  const { token } = useContext(authContext);
  const [enrolDatePrice, setEnrolDatePrice] = useState({
    earlyBirdDate,
    normalPrice1T,
    earlyBird1T,
    normalPrice2T,
    earlyBird2T,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEnrolDatePrice((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    // event.preventDefault();

    const authStr = 'Bearer ' + token;

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/enrolDatePrice/update/' + _id,
        enrolDatePrice,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    // window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-11 col-lg-2'>
          <div>
            Early Bird Date: {enrolDatePrice.earlyBirdDate.substr(0, 10)}
          </div>
          <input
            name='earlyBirdDate'
            type='date'
            style={{ width: 160 }}
            value={enrolDatePrice.earlyBirdDate}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-2'>
          <div>Normal Price 1 Term</div>
          <input
            type='text'
            name='normalPrice1T'
            style={{ width: 100 }}
            value={enrolDatePrice.normalPrice1T}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-2'>
          <div>Early Bird 1 Term</div>
          <input
            type='text'
            name='earlyBird1T'
            style={{ width: 100 }}
            value={enrolDatePrice.earlyBird1T}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-2'>
          <div>Normal Price 2 Terms</div>
          <input
            type='text'
            name='normalPrice2T'
            style={{ width: 100 }}
            value={enrolDatePrice.normalPrice2T}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-2'>
          <div>Early Bird 2 Terms</div>
          <input
            type='text'
            name='earlyBird2T'
            style={{ width: 100 }}
            value={enrolDatePrice.earlyBird2T}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-2'>
          <button className='btn btn-sm btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
