import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../../context/authContext';

export default function SubCatEdit({ index, scat }) {
  const { _id, name, nameCN, icon } = scat;

  const { token } = useContext(authContext);
  const [editSubCat, setEditSubCat] = useState({ name, nameCN, icon });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditSubCat((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/subCats/update/' + _id,
        editSubCat,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>name: </span>
          <input
            required
            type='text'
            name='name'
            style={{ width: 90 }}
            value={editSubCat.name}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>nameCN: </span>
          <input
            required
            type='text'
            name='nameCN'
            style={{ width: 90 }}
            value={editSubCat.nameCN}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>icon: </span>
          <input
            required
            type='text'
            name='icon'
            style={{ width: 90 }}
            value={editSubCat.icon}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
