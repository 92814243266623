import React, { useContext } from 'react';

import Title from '../Title';
import authContext from '../../context/authContext';

export default function Contact() {
  const { langEn } = useContext(authContext);

  return (
    <>
      <Title
        bg_img='/img/title_contact.png'
        title={langEn ? <span>CONTACT US</span> : '联系'}
      />
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 my-3'>
            <h3>Address</h3>
            <div
              className='container p-3'
              style={{
                color: 'black',
                backgroundColor: 'var(--mainWhite)',
                boxShadow: '1px 1px',
              }}
            >
              INFINITY ART SCHOOL
              <br />
              2/120A Rowe St, Eastwood NSW 2122
              <br />
              Mobile: 0435 090 818
              <br />
              Email:{' '}
              <a href='mailto:InfinityArtEW@gmail.com'>
                InfinityArtEW@gmail.com
              </a>
              <br />
              WeChat QR Code:
              <br />
              <img
                src='/img/contact_infArtWeChat.png'
                alt='infArt'
                width='200px'
              />
            </div>
          </div>
          <div className='col-lg-6 my-3'>
            <h3>Map</h3>
            <iframe
              title='Infinity Art School'
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3315.8065498806864!2d151.08094415204914!3d-33.79149342238991!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12a437440239ff%3A0x8dfd672e28f66a1b!2sInfinity%20Art%20School!5e0!3m2!1sen!2sau!4v1599633780389!5m2!1sen!2sau'
              width='100%'
              height='400px'
              frameBorder='0'
              style={{ border: '5px', boxShadow: '1px 1px #232528' }}
              allowFullScreen=''
              aria-hidden='false'
              tabIndex='0'
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
}
