import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function UserAddress({
  user,
  showUpdateUserModal,
  updateOwner,
}) {
  const { langEn, token } = useContext(authContext);
  const [updateUser, setUpdateUser] = useState(user);
  const [showUserUpdate, setShowUserUpdate] = useState(false);

  useEffect(() => {
    setShowUserUpdate(showUpdateUserModal);
    setUpdateUser(user);
  }, [user, showUpdateUserModal]);

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setUpdateUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setShowUserUpdate(false);
    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/update/self',
        updateUser,
        {
          headers: { Authorization: authStr },
        }
      );
      updateOwner(updateUser);
    } catch (error) {
      console.error(error.response.data.message);
    }
  }

  return (
    <>
      <button
        className='btn btn-outline-light btn-sm'
        onClick={() => setShowUserUpdate(true)}
      >
        {langEn ? 'Edit Addr' : '修改地址'}
      </button>

      <Modal show={showUserUpdate} onHide={() => setShowUserUpdate(false)}>
        <Modal.Header closeButton>
          <h5>
            Change Address for {updateUser.fname} {updateUser.lname}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className='form-row'>
              <div className='col-md-6'>Email: {updateUser.email}</div>
              <div className='col-md-6'>Phone: {updateUser.phone}</div>
            </div>

            <div className='form-row'>
              <div className='form-group col-12'>
                <label>Street Address: </label>
                <input
                  className='form-control'
                  type='text'
                  name='line1'
                  value={updateUser.address.line1}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-6'>
                <label>Suburb: </label>
                <input
                  className='form-control'
                  type='text'
                  name='suburb'
                  value={updateUser.address.suburb}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>State: </label>
                <input
                  className='form-control'
                  type='text'
                  name='state'
                  value={updateUser.address.state}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>Post code: </label>
                <input
                  className='form-control'
                  type='text'
                  name='postcode'
                  value={updateUser.address.postcode}
                  onChange={handleStreetInput}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={handleSubmit}>
            Save Changes
          </button>
          <button
            className='btn btn-secondary'
            onClick={() => setShowUserUpdate(false)}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
