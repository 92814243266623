import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function EnrolSlotAdd({ nSlots }) {
  const { token } = useContext(authContext);
  const [newSlot, setNewSlot] = useState({
    slotID: 0,
    slotName: '',
    active: true,
  });

  useEffect(() => {
    setNewSlot({ slotID: nSlots + 1, slotName: '', active: true });
  }, [nSlots]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSlot((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setNewSlot((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/enrolSlots/add',
        newSlot,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewSlot({
      slotID: 0,
      slotName: '',
    });
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2 bg-white'>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>slotID: </span>
          <input
            required
            type='number'
            style={{ width: 80 }}
            name='slotID'
            value={newSlot.slotID}
            onChange={handleChange}
            placeholder='slotID'
          />
        </div>
        <div className='col-11 col-lg-3'>
          <span className='d-lg-none'>slotName: </span>
          <input
            required
            type='text'
            style={{ width: 300 }}
            name='slotName'
            value={newSlot.slotName}
            onChange={handleChange}
            placeholder='slotName'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>active: </span>
          <input
            type='checkbox'
            name='onShelf'
            checked={newSlot.active}
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 mx-atuo col-lg-2'>
          <span className='d-lg-none'>Save: </span>
          <button className='btn btn-sm btn-link' type='submit'>
            <i className='fas fa-save' />
            <span>+</span>
          </button>
        </div>
      </div>
    </form>
  );
}
