import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
// import productContext from '../../context/productContext';

export default function ProductManageEdit({ index, enrolSlot }) {
  // const { catList, subCats } = useContext(productContext);

  const { _id, slotID, slotName, active } = enrolSlot;

  const { token } = useContext(authContext);
  const [editSlot, setEditSlot] = useState({
    slotID,
    slotName,
    active,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditSlot((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditSlot((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/enrolSlots/update/' + _id,
        editSlot,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1'>
          <input
            name='slotID'
            type='number'
            style={{ width: 80 }}
            value={editSlot.slotID}
            onChange={handleChange}
            placeholder='slotID'
          />
        </div>
        <div className='col-11 col-lg-3'>
          <input
            type='text'
            name='slotName'
            style={{ width: 300 }}
            value={editSlot.slotName}
            onChange={handleChange}
            placeholder='slotName'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>active: </span>
          <input
            type='checkbox'
            name='active'
            checked={editSlot.active}
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 col-lg-2'>
          <button className='btn btn-sm btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
