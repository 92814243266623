/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';

import Title from '../Title';
import OrderItem from './OrderItem';
import authContext from '../../context/authContext';

function OrderManage() {
  const { token, langEn } = useContext(authContext);
  const [ordersTbDel, setOrdersTbDel] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/orders/all',
          {
            headers: { Authorization: authStr },
          }
        );

        setOrdersTbDel([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    token && fetchOrders();
  }, [token]);

  async function handleSubmit(event, item) {
    event.preventDefault();

    // const editOrder = {
    //   _id,
    //   owner,
    //   productList,
    //   paidDate,
    //   status: 'delivered',
    //   deliveredDate: new Date(),
    // };

    item.status = 'delivered';
    item.deliveredDate = new Date();

    const authStr = 'Bearer ' + token;

    try {
      await Axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/orders/update/' + item._id,
        item,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <React.Fragment>
      <Title title={langEn ? 'Order Management' : '订单管理'} />
      <div className='container-fluid mt-3'>
        <div className='row'>
          {ordersTbDel.map((item) => {
            return (
              <OrderItem
                key={item._id}
                title={
                  <div className='row'>
                    {item.deliveredDate ? (
                      <div className='col-4'>Delivered to:</div>
                    ) : (
                      <div className='col-4'>
                        <form onSubmit={(e) => handleSubmit(e, item)}>
                          <button
                            className='btn btn-outline-primary btn-sm'
                            type='submit'
                          >
                            <span role='img' aria-label='deliver'>
                              Deliver🚚
                            </span>
                          </button>
                        </form>
                      </div>
                    )}
                    <div className='col-8'>
                      {item.owner.fname + ' ' + item.owner.lname} (
                      {item.owner.phone})
                    </div>
                    <div className='col-4'></div>
                    <div className='col-8'>{item.owner.address.line1}</div>
                    <div className='col-4'></div>
                    <div className='col-8'>
                      {item.owner.address.suburb} {item.owner.address.state}{' '}
                      {item.owner.address.postcode}
                    </div>
                  </div>
                }
                orderId={item._id}
                paidDate={item.paidDate}
                paymentId={item.paymentId}
                deliveredDate={item.deliveredDate}
                productList={item.productList}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default OrderManage;
