import React, { useState } from 'react';

import SubCatColumns from './SubCatColumns';
import SubCatItem from './SubCatItem';
import SubCatAdd from './SubCatAdd';
import SubCatEdit from './SubCatEdit';

export default function SubCatManage({ subCat, categoryId }) {
  const [editCategoryId, setEditCategoryId] = useState('');
  const setEdit = (catId) => {
    setEditCategoryId(catId);
  };

  return (
    <div>
      <SubCatColumns />
      <SubCatAdd categoryId={categoryId} />
      {subCat.map((scat) => {
        if (scat._id === editCategoryId) {
          return <SubCatEdit key={scat._id} scat={scat} />;
        } else {
          return <SubCatItem key={scat._id} scat={scat} setEdit={setEdit} />;
        }
      })}
    </div>
  );
}
