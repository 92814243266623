import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Title from '../Title';
import EnrolDatePrice from './EnrolDatePrice';
import EnrolSlotColumns from './EnrolSlotColumns';
import EnrolSlotItem from './EnrolSlotItem';
import EnrolSlotAdd from './EnrolSlotAdd';
import EnrolSlotEdit from './EnrolSlotEdit';
import authContext from '../../context/authContext';

export default function EnrolSlot() {
  const { langEn } = useContext(authContext);
  const [enrolSlots, setEnrolSlots] = useState([]);
  const [enrolDatePrice, setEnrolDatePrice] = useState({
    earlyBirdDate: null,
    normalPrice1T: 0,
    earlyBird1T: 0,
    normalPrice2T: 0,
    earlyBird2T: 0,
  });

  useEffect(() => {
    async function fetchEnrolDatePrice() {
      try {
        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/enrolDatePrice/'
        );
        setEnrolDatePrice(res.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchEnrolDatePrice();
  }, []);

  useEffect(() => {
    async function fetchSlot() {
      try {
        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/enrolSlots/'
        );
        res &&
          res.data.length > 0 &&
          res.data.sort((a, b) => b.active - a.active || a.slotID - b.slotID);
        setEnrolSlots(res.data);
      } catch (error) {
        console.error(error);
      }
    }
    fetchSlot();
  }, []);

  const [editSlotId, setEditSlotId] = useState('');
  const setEdit = (productId) => {
    setEditSlotId(productId);
  };

  return (
    <div className='container-fluid'>
      <Title title={langEn ? 'Manage Enrol Slots' : '管理'} />

      <div className='bg-light text-dark p-3'>
        <h3>Edit Enrol Date / Prices</h3>
        {enrolDatePrice && enrolDatePrice.earlyBirdDate && (
          <EnrolDatePrice enrolDP={enrolDatePrice} />
        )}
      </div>
      <hr />
      <div className='container-fluid bg-light text-dark p-3'>
        <h3>Edit Enrol Slots</h3>
        <EnrolSlotColumns />
        <EnrolSlotAdd nSlots={enrolSlots.length} />
        {enrolSlots.map((eSlot, index) => {
          if (eSlot._id === editSlotId) {
            return (
              <EnrolSlotEdit key={eSlot._id} index={index} enrolSlot={eSlot} />
            );
          } else {
            return (
              <EnrolSlotItem
                key={eSlot._id}
                index={index}
                enrolSlot={eSlot}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
