import React, { useContext } from 'react';

import authContext from '../../context/authContext';

export default function OrderItem({
  title,
  orderId,
  paidDate,
  paymentId,
  deliveredDate,
  productList,
}) {
  const { langEn, admin } = useContext(authContext);
  let price_total = 0;
  let GST_total = 0;
  productList.forEach((item) => {
    price_total += item.price * item.quantity;
    if (item.GST) {
      GST_total += item.GST * item.quantity;
    } else {
      GST_total += item.includeGST ? (item.price * item.quantity) / 11 : 0;
    }
  });

  return (
    // <div className='col-auto m-2'>
    <div
      className='card border-info m-2'
      style={{ minWidth: '25rem', maxWidth: '25rem' }}
    >
      {title && <div className='card-header'>{title}</div>}
      {orderId && (
        <div className='card-header'>
          <div className='row'>
            {orderId && (
              <>
                <div className='col-3'>Order ID: </div>
                <div className='col-9 text-right'>{orderId}</div>
              </>
            )}

            {paymentId && (
              <>
                <div className='col-3'>PaymentID: </div>
                <div className='col-9 text-right'>
                  {paymentId ? paymentId : ''}
                </div>{' '}
              </>
            )}
            {paidDate && (
              <>
                <div className='col-3'>Paid:</div>
                <div className='col-9 text-right'>
                  {new Date(paidDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }) +
                    ', ' +
                    new Date(paidDate).toLocaleDateString('en-AU')}
                </div>
              </>
            )}
            {deliveredDate && (
              <>
                <div className='col-3'>Delivered:</div>
                <div className='col-9 text-right'>
                  {new Date(deliveredDate).toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                  }) +
                    ', ' +
                    new Date(deliveredDate).toLocaleDateString('en-AU')}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <div className='card-body'>
        {productList.map((item, orderId) => {
          return (
            <div key={orderId} className='row'>
              <div className='col-9'>
                {admin && item.shelf_pos + ': '}
                {langEn ? item.name : item.cnName}
                {item.quantity > 1 && (
                  <div className='text-muted'>
                    &emsp; {langEn ? 'Qty' : '数量'} {item.quantity} @ $
                    {item.price}/{langEn ? item.unit : item.cnUnit}
                  </div>
                )}
              </div>
              <div className='col-3 text-right'>
                ${(item.price * item.quantity).toFixed(2)}
              </div>
            </div>
          );
        })}
      </div>
      <div className='card-footer font-italic'>
        <div className='container'>
          <div className='row'>
            <div className='col-9 text-right'>{langEn ? 'Total' : '总计'}:</div>
            <h5 className='col-3 text-right'>${price_total.toFixed(2)}</h5>
          </div>
          <div className='row text-muted'>
            <div className='col-9 text-right'>
              {langEn ? 'incl GST' : '含消费税'}:
            </div>
            <div className='col-3 text-right'>${GST_total.toFixed(2)}</div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
