import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function ProductManageItem({ index, product, setEdit }) {
  const {
    _id,
    name,
    cnName,
    img,
    brand,
    cnBrand,
    info,
    infoCN,
    categoryId,
    subCatId,
    stock,
    price,
    includeGST,
    onShelf,
    unit,
    cnUnit,
    shelf_pos,
  } = product;

  const { token } = useContext(authContext);

  async function deleteProd(productId) {
    const authStr = 'Bearer ' + token;
    // const uploadProduct = new FormData();
    // uploadProduct.append('productImage', productImg);
    // // field name 'productImage' must match backend post: upload.single('productImage')

    // Object.keys(editProduct).forEach(function (k) {
    //   uploadProduct.append(k, editProduct[k]);
    // });

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/products/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>name: </span>
          {name}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          {cnName}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>category: </span>
          {categoryId && categoryId.name}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>sub-Category: </span>
          {subCatId && subCatId.name}
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + img}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='product'
          />
        </div>
        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>price: </span>${price}
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>GST: </span>
          <input type='checkbox' checked={includeGST} disabled />
        </div>
        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>stock: </span>
          {stock}
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input type='checkbox' checked={onShelf} disabled />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>shelf_pos: </span>
          {shelf_pos}
        </div>
        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' onClick={() => setEdit(_id)}>
            <i className='fas fa-edit' />
          </button>
          {!onShelf && (
            <button className='btn btn-link' onClick={() => deleteProd(_id)}>
              <i className='fas fa-trash-alt' />
            </button>
          )}
        </div>
      </div>

      <div className={index % 2 ? 'row bg-white' : 'row'}>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>unit: </span>
          {unit}
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>单元: </span>
          {cnUnit}
        </div>

        <div className='col-11  col-lg-1'>{brand}</div>
        <div className='col-11  col-lg-1'>{cnBrand}</div>

        <div className='col-11  col-lg-2'>
          {info && info.substring(0, 20) + '...'}
        </div>
        <div className='col-11  col-lg-2'>
          {infoCN && infoCN.substring(0, 8) + '......'}
        </div>
      </div>
    </>
  );
}
