import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';
import authContext from '../../context/authContext';

import Title from '../Title';
import UsersColumns from './UsersColumns';
import UsersItem from './UsersItem';
import UsersEdit from './UsersEdit';

export default function UserManage() {
  const { token, langEn } = useContext(authContext);

  const [allUsers, setAllUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/all',
          {
            headers: { Authorization: authStr },
          }
        );
        result.data.sort((a, b) => b.admin - a.admin || a.lname - b.lname);

        setAllUsers([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };
    token && fetchUsers();
  }, [token]);

  const [editUserId, setEditUserId] = useState('');
  const setEdit = (UserId) => {
    setEditUserId(UserId);
  };

  return (
    <div className='container-fluid'>
      <Title title={langEn ? 'User Management' : '用户管理'} />
      <div className='container-fluid bg-light text-dark'>
        <UsersColumns />
        {allUsers.map((user, index) => {
          if (user._id === editUserId) {
            return <UsersEdit key={user._id} index={index} user={user} />;
          } else {
            return (
              <UsersItem
                key={user._id}
                index={index}
                user={user}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
