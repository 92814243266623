import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function CategoryEdit({ index, cat }) {
  const { _id, name, nameCN, catID, icon, note, img, subCat } = cat;

  const { token } = useContext(authContext);
  const [editCat, setEditCat] = useState({
    name,
    nameCN,
    catID,
    icon,
    note,
    img,
    subCat,
  });

  const [catImg, setCatImg] = useState(null);
  const handleSelectedImage = (e) => {
    setCatImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditCat((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    const uploadCat = new FormData();
    uploadCat.append('catImage', catImg);
    Object.keys(editCat).forEach(function (k) {
      uploadCat.append(k, editCat[k]);
    });

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/categories/update/' + _id,
        uploadCat,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>name: </span>
          <input
            required
            type='text'
            name='name'
            style={{ width: 90 }}
            value={editCat.name}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>nameCN: </span>
          <input
            required
            type='text'
            name='nameCN'
            style={{ width: 90 }}
            value={editCat.nameCN}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>catID: </span>
          <input
            type='number'
            name='catID'
            style={{ width: 90 }}
            value={editCat.catID}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>icon: </span>
          <input
            required
            type='text'
            name='icon'
            style={{ width: 90 }}
            value={editCat.icon}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>image: </span>
          <input
            type='file'
            name='img'
            // value={img}
            onChange={handleSelectedImage}
          />
        </div>

        <div className='col-11 col-lg-4'>
          <span className='d-lg-none'>note: </span>
          <input
            type='text'
            name='note'
            style={{ width: 400 }}
            value={editCat.note}
            onChange={handleChange}
            placeholder='note'
          />
        </div>

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
