import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
// import productContext from '../../context/productContext';

export default function ProductManageEdit({ index, product }) {
  // const { catList, subCats } = useContext(productContext);

  const {
    _id,
    name,
    cnName,
    img,
    brand,
    cnBrand,
    info,
    infoCN,
    categoryId,
    subCatId,
    stock,
    price,
    includeGST,
    onShelf,
    unit,
    cnUnit,
    shelf_pos,
  } = product;

  const { token } = useContext(authContext);
  const [editProduct, setEditProduct] = useState({
    name,
    cnName,
    img,
    brand,
    cnBrand,
    info,
    infoCN,
    // categoryId: categoryId.toString(),
    // subCatId: subCatId.toString(),
    stock,
    price,
    includeGST,
    onShelf,
    unit,
    cnUnit,
    shelf_pos,
  });
  // const subCatList = subCats.filter(
  //   (item) => item.categoryId === editProduct.categoryId._id
  // );

  const [productImg, setProductImg] = useState(null);
  const handleSelectedImage = (e) => {
    setProductImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    const uploadProduct = new FormData();
    uploadProduct.append('productImage', productImg);
    // field name 'productImage' must match backend post: upload.single('productImage')

    Object.keys(editProduct).forEach(function (k) {
      uploadProduct.append(k, editProduct[k]);
    });

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/products/update/' + _id,
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>name: </span>
          {name}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          {cnName}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>category: </span>
          {categoryId && categoryId.name}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>sub-Category: </span>
          {subCatId && subCatId.name}
        </div>

        <div className='col-11 col-lg-1 '>
          <span className='d-lg-none'>image: </span>
          <input type='file' name='img' onChange={handleSelectedImage} />
        </div>
        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>price: </span>
          <input
            required
            type='number'
            name='price'
            style={{ width: 90 }}
            value={editProduct.price}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>GST: </span>
          <input type='checkbox' checked={editProduct.includeGST} disabled />
        </div>

        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>stock: </span>
          <input
            required
            type='text'
            name='stock'
            style={{ width: 90 }}
            value={editProduct.stock}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input
            type='checkbox'
            name='onShelf'
            checked={editProduct.onShelf}
            onChange={handleCheck}
          />
        </div>
        <div className='col-11 col-lg-1'>
          <input
            required
            name='shelf_pos'
            type='text'
            style={{ width: 90 }}
            value={editProduct.shelf_pos}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>

      <div className={index % 2 ? 'row bg-white' : 'row'}>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>unit: </span>
          <input
            name='unit'
            type='text'
            style={{ width: 90 }}
            value={editProduct.unit}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>单元: </span>
          <input
            name='cnUnit'
            type='text'
            style={{ width: 90 }}
            value={editProduct.cnUnit}
            onChange={handleChange}
          />
        </div>

        <div className='col-11 col-lg-1'>
          <input
            name='brand'
            type='text'
            style={{ width: 80 }}
            value={editProduct.brand}
            onChange={handleChange}
            placeholder='Brand'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <input
            type='text'
            name='cnBrand'
            style={{ width: 80 }}
            value={editProduct.cnBrand}
            onChange={handleChange}
            placeholder='品牌'
          />
        </div>

        <div className='col-11 col-lg-2'>
          <input
            name='info'
            type='text'
            style={{ width: 200 }}
            value={editProduct.info}
            onChange={handleChange}
            placeholder='Product Information'
          />
        </div>
        <div className='col-11 col-lg-2'>
          <input
            type='text'
            name='infoCN'
            style={{ width: 200 }}
            value={editProduct.infoCN}
            onChange={handleChange}
            placeholder='商品信息'
          />
        </div>
      </div>
    </form>
  );
}
