import React from 'react';

export default function Footer() {
  const footerStyle = {
    backgroundColor: '#003c71',
    fontSize: '14px',
    color: 'white',
    // borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '5px',
    position: 'fixed',
    left: '0',
    bottom: '0',
    height: '30px',
    width: '100%',
  };

  const phantom = {
    display: 'block',
    padding: '10px',
    height: '30px',
    width: '100%',
  };

  return (
    <div>
      <div style={phantom} />
      <div style={footerStyle}>
        <p>
          &copy; 2020{' '}
          <a href='https://www.infinityart.com.au/' className='text-light'>
            <u>Infinity Art Pty Ltd</u>
          </a>
          , ABN 73 613 712 351,
        </p>
      </div>
    </div>
  );
}
