import React, { useContext } from 'react';

import authContext from '../context/authContext';

export default function Title({ bg_img, name, title, text }) {
  const { langEn, userName } = useContext(authContext);

  const titleStyle = {
    backgroundColor: 'gray',
    backgroundImage: 'url(' + bg_img + ')',
    backgroundSize: 'auto 100%',
    margin: 0,
  };

  return (
    <div>
      <div
        className='text-white d-none d-md-block py-3 position-relative'
        style={titleStyle}
      >
        <h1 className='text-center'>{title}</h1>
        {name}
        {text && <h5 className='text-left'>{text}</h5>}
        <div
          style={{
            position: 'absolute',
            top: '5px',
            right: '10px',
          }}
        >
          {userName &&
            (langEn ? (
              <div>Welcome: {userName}</div>
            ) : (
              <div>欢迎: {userName}</div>
            ))}
        </div>
      </div>

      {/* mobile phone title */}
      <div
        className='text-white d-md-none py-3 position-relative'
        style={titleStyle}
      >
        <h5 className='text-center'>
          {title}
          {name}
        </h5>
        {text && <h5 className='text-left'>{text}</h5>}
        <div
          style={{
            position: 'absolute',
            top: '2px',
            right: '2px',
          }}
        >
          {userName &&
            (langEn ? (
              <div>Welcome: {userName}</div>
            ) : (
              <div>欢迎: {userName}</div>
            ))}
        </div>
      </div>
    </div>
  );
}
