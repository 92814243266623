import React from 'react';

export default function UsersColumns() {
  return (
    <div className='row'>
      <div className='col-11 col-lg-2'>Name</div>
      <div className='col-11 col-lg-2'>email</div>
      <div className='col-11 col-lg-1'>admin</div>
      <div className='col-11 col-lg-2'>phone</div>
      <div className='col-11 col-lg-3'>address</div>

      <div className='col-11 col-lg-2'>orders | edit | delete</div>
    </div>
  );
}
