import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../../context/authContext';

export default function SubCatAdd({ categoryId }) {
  const { token } = useContext(authContext);
  const [newSubCat, setNewSubCat] = useState({
    categoryId,
    name: '',
    nameCN: '',
    icon: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewSubCat((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    setNewSubCat((prevState) => {
      return {
        ...prevState,
        categoryId,
      };
    });

    const authStr = 'Bearer ' + token;

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/subCats/add',
        newSubCat,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewSubCat({
      name: '',
      nameCN: '',
      icon: '',
      subCat: [],
    });
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2'>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>Category: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='name'
            value={newSubCat.name}
            onChange={handleChange}
            placeholder='subCat Name'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='nameCN'
            value={newSubCat.nameCN}
            onChange={handleChange}
            placeholder='子类名称'
          />
        </div>

        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>icon: </span>
          <input
            type='text'
            name='icon'
            style={{ width: 80 }}
            value={newSubCat.icon}
            onChange={handleChange}
            placeholder='icon'
          />
        </div>

        <div className='col-11 mx-atuo col-lg-1'>
          <span className='d-lg-none'>Save: </span>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />+
          </button>
        </div>
      </div>
    </form>
  );
}
