import React, { useState, useContext } from 'react';

import authContext from '../../context/authContext';
import Modal from 'react-bootstrap/Modal';

export default function StuArtGalleryModal({ artWork }) {
  const { title, titleCN, author, imgPreview } = artWork;
  const { langEn } = useContext(authContext);

  const [show, setShow] = useState(false);

  const buttonStyle = {
    position: 'absolute',
    bottom: '0px',
    right: '0px',
    opacity: 0.8,
  };

  return (
    <>
      <button
        className='btn btn-info btn-sm'
        style={buttonStyle}
        onClick={() => setShow(true)}
      >
        Preview
      </button>

      <Modal
        className='text-dark'
        size='lg'
        show={show}
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {langEn ? title : titleCN} by {author}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            className='card-img'
            src={process.env.REACT_APP_ASSET_URL + '/' + imgPreview}
            alt='artwork'
          />
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-primary' onClick={() => setShow(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
