import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
// import productContext from '../../context/productContext';

export default function EnrolOrderEdit({ index, enrolOrder }) {
  // const { catList, subCats } = useContext(productContext);

  const {
    _id,
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    payInfo,
    // submitDate,
    // payDate,
    receipt,
    confirm,
    note,
  } = enrolOrder;

  const { token } = useContext(authContext);
  const [editEnrolOrder, setEditEnrolOder] = useState({
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    payInfo,
    receipt,
    confirm,
    note,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditEnrolOder((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditEnrolOder((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/enrolOrders/update/' + _id,
        editEnrolOrder,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-1' : 'row mt-1 bg-white'}>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>Student/DoB:Gender </span>
          <div>{student}</div>
          <div>
            {DoB}:{gender}
          </div>
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>parentEmail/parentPhone </span>
          <div>{parentEmail}</div>
          <div>{parentPhone}</div>
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>selSlots: </span>
          {selSlots.map((slot, index) => (
            <div key={index}>{slot}</div>
          ))}
        </div>
        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>payAmount via payMethod / payInfo: </span>
          <div>
            ${payAmount}, via {payMethod}
          </div>
          <div>{payInfo}</div>
        </div>

        <div className='col-11 col-md-2'>
          <span className='d-lg-none'>Confirm #receipt/note: </span>#{receipt}
          <div>
            <input
              type='text'
              name='note'
              style={{ width: 200 }}
              value={editEnrolOrder.note}
              onChange={handleChange}
              placeholder='note'
            />
          </div>
        </div>

        <div className='col-11 col-md-1'>
          <span className='d-lg-none'>Confirm #receipt/note: </span>
          <input
            type='checkbox'
            checked={editEnrolOrder.confirm}
            name='confirm'
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 col-md-1'>
          <button className='btn btn-sm btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>

        {/* <div className='col-11 col-md-2 text-right'>Booking Date:</div>
        <div className='col-11 col-md-2'>
          {submitDate ? new Date(submitDate).toString().substr(0, 21) : ''}
        </div>

        <div className='col-11 col-md-2 text-right'>Confirm Date:</div>
        <div className='col-11 col-md-2'>
          {payDate ? new Date(payDate).toString().substr(0, 21) : ''}
        </div> */}
      </div>
    </form>
  );
}
