import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Title from '../Title';
import EnrolOrderColumn from './EnrolOrderColumn';
import EnrolOrderItem from './EnrolOrderItem';
import EnrolOrderEdit from './EnrolOrderEdit';
import authContext from '../../context/authContext';

export default function EnrolOrder() {
  const { langEn, token } = useContext(authContext);
  const [enrolOrders, setEnrolOrders] = useState([]);

  useEffect(() => {
    async function fetchEnrolOrders() {
      const authStr = 'Bearer ' + token;

      try {
        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/enrolOrders/all',
          {
            headers: { Authorization: authStr },
          }
        );
        setEnrolOrders(res.data);
      } catch (error) {
        console.error(error);
      }
    }
    token && fetchEnrolOrders();
  }, [token]);

  const [editOrderId, setEditOderId] = useState('');
  const setEdit = (odrId) => {
    setEditOderId(odrId);
  };

  return (
    <div>
      <Title title={langEn ? 'Manage Enrol Orders' : '管理'} />
      <div className='container-fluid bg-light text-dark'>
        <EnrolOrderColumn />
        {enrolOrders.map((eOdr, index) => {
          if (eOdr._id === editOrderId) {
            return (
              <EnrolOrderEdit key={eOdr._id} index={index} enrolOrder={eOdr} />
            );
          } else {
            return (
              <EnrolOrderItem
                key={eOdr._id}
                index={index}
                enrolOrder={eOdr}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
