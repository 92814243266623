import React, { useState, useContext } from 'react';
import axios from 'axios';
import { Modal, Alert } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

import authContext from '../../context/authContext';
import ForgotPassword from './ForgotPassword';

export default function AuthModal() {
  const { langEn, login } = useContext(authContext);
  const [showAuthModal, setShowAuthModal] = useState(false);

  const [isLoginMode, setIsLoginMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState();
  const [activating, setActivating] = useState(false);

  const [newUser, setNewUser] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    password2: '',
    phone: '',
    address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
    student: { name: '', DoB: '', Gender: '' },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      let responseData = {};
      if (!isLoginMode) {
        if (newUser.password !== newUser.password2) {
          setErrorState("Confirm password doesn't match! Try again.");
          setIsLoading(false);
          return;
        }
        responseData = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/register',
          {
            newUser,
            activateLink:
              process.env.REACT_APP_FRONTEND_URL + '/user/activate/',
          }
        );
      } else {
        responseData = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/users/login',
          {
            email: newUser.email,
            password: newUser.password,
            activateLink:
              process.env.REACT_APP_FRONTEND_URL + '/user/activate/',
          }
        );
      }
      setIsLoading(false);

      if (responseData.status === 200) {
        // logged in
        login(
          responseData.data.userId,
          responseData.data.token,
          responseData.data.fname,
          responseData.data.admin
        );
        setShowAuthModal(false);
      } else if (responseData.status === 201) {
        // registered - need activation
        setActivating(true);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorState(error.response.data.message);
      console.error(error.response.data.message);
    }
  }

  return (
    <>
      <button
        className='btn btn-sm btn-outline-warning'
        onClick={() => setShowAuthModal(true)}
      >
        <>
          <i className='fas fa-user-check'></i>
          <span className='d-none d-md-inline'>
            {langEn ? <b>Login</b> : <b>登录</b>}
          </span>
        </>
      </button>

      <Modal show={showAuthModal} onHide={() => setShowAuthModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <ul className='nav nav-tabs card-header-tabs'>
              <li className='nav-item'>
                <button
                  className='btn btn-outline-warning'
                  onClick={() => setIsLoginMode(true)}
                >
                  <h5>
                    <i className='fas fa-user-check'></i>
                    {langEn ? 'Login' : '登录'}
                  </h5>
                </button>
              </li>
              <li className='nav-item'>
                <button
                  className='btn btn-outline-primary'
                  onClick={() => setIsLoginMode(false)}
                >
                  <h5>
                    <i className='fas fa-user-plus'></i>
                    {langEn ? 'Register' : '注册'}
                  </h5>
                </button>
              </li>
              {isLoading && <Spinner animation='border' />}
            </ul>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className='card-body'>
              <div className='form-row'>
                {!isLoginMode && (
                  <>
                    <div className='form-group col-md-6'>
                      <input
                        className='form-control'
                        required
                        type='text'
                        name='fname'
                        value={newUser.fname}
                        onChange={handleChange}
                        placeholder='👤 First name'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <input
                        className='form-control'
                        required
                        type='text'
                        name='lname'
                        value={newUser.lname}
                        onChange={handleChange}
                        placeholder='Last name 👤'
                      />
                    </div>
                    <div className='form-group col-md-6'>
                      <input
                        className='form-control'
                        type='text'
                        name='phone'
                        value={newUser.phone}
                        pattern='^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{2}( |-){0,1}[0-9]{1}( |-){0,1}[0-9]{3}$'
                        onChange={handleChange}
                        placeholder='☎ Mobile Number'
                      />
                    </div>
                  </>
                )}

                <div className='form-group col-md-6'>
                  <input
                    className='form-control'
                    required
                    type='email'
                    name='email'
                    value={newUser.email}
                    onChange={handleChange}
                    placeholder='📧 email'
                  />
                </div>
                <div className='form-group col-md-6'>
                  <input
                    className='form-control'
                    required
                    type='password'
                    name='password'
                    pattern='.{6,}'
                    value={newUser.password}
                    placeholder='🔒Password (six+ characters)'
                    onChange={handleChange}
                  />
                  {!isLoginMode && (
                    <small>Password: six or more characters.</small>
                  )}
                </div>
                {!isLoginMode && (
                  <div className='form-group col-md-6'>
                    <input
                      className='form-control'
                      required
                      type='password'
                      name='password2'
                      value={newUser.password2}
                      onChange={handleChange}
                      placeholder='🔒confirm password'
                    />
                    <small>Password: six or more characters.</small>
                  </div>
                )}
              </div>

              {!isLoginMode && (
                <div className='form-row'>
                  <div className='form-group col-12'>
                    <input
                      className='form-control'
                      type='text'
                      name='line1'
                      value={newUser.address.line1}
                      onChange={handleStreetInput}
                      placeholder='📫 Street address'
                    />
                  </div>
                  <div className='form-group col-md-6'>
                    <input
                      className='form-control'
                      type='text'
                      name='suburb'
                      value={newUser.address.suburb}
                      onChange={handleStreetInput}
                      placeholder='Suburb'
                    />
                  </div>
                  <div className='form-group col-6 col-md-3'>
                    <input
                      className='form-control'
                      type='text'
                      name='state'
                      value={newUser.address.state}
                      onChange={handleStreetInput}
                      placeholder='State'
                    />
                  </div>
                  <div className='form-group col-6 col-md-3'>
                    <input
                      className='form-control'
                      type='text'
                      name='postcode'
                      value={newUser.address.postcode}
                      onChange={handleStreetInput}
                      placeholder='Post code'
                    />
                  </div>
                </div>
              )}
            </div>
            <Alert
              show={activating}
              variant='info'
              onClose={() => setActivating(false)}
              dismissible
            >
              <h5>Account Activation Required</h5>
              <p>
                An account activation email has been sent to you. Please check
                your email and follow the instructions to activate your account.
              </p>
              <p>
                Note: if you cannot find the activation email, please check your{' '}
                <span style={{ backgroundColor: 'yellow' }}>Spam Email</span> or{' '}
                <span style={{ backgroundColor: 'yellow' }}>Junk Email</span>{' '}
                folder.
              </p>
            </Alert>
          </Modal.Body>

          <Modal.Footer>
            {activating ? (
              <Link
                className='btn btn-info'
                to='/'
                onClick={() => setShowAuthModal(false)}
              >
                Return
              </Link>
            ) : (
              <div className='card-footer'>
                {isLoginMode ? (
                  <>
                    <div className='d-flex'>
                      <button className='btn btn-warning ml-auto' type='submit'>
                        <h5>
                          <i className='fas fa-user-check'></i>
                          {langEn ? 'Login' : '登录'}
                        </h5>
                      </button>
                    </div>
                    <Alert
                      show={!!errorState}
                      variant='info'
                      onClose={() => setErrorState(null)}
                      dismissible
                    >
                      <h5>Error: {errorState}</h5>
                      {errorState === 'wrong password' && (
                        <ForgotPassword
                          emailInput={newUser.email}
                          setShowAuthModal={setShowAuthModal}
                        />
                      )}
                      {errorState === 'user not found' && (
                        <p>
                          Please Register{' '}
                          <span role='img' aria-label='above'>
                            👆
                          </span>{' '}
                        </p>
                      )}
                    </Alert>
                  </>
                ) : (
                  <button className='btn btn-primary' type='submit'>
                    <h5>
                      <i className='fas fa-user-plus'></i>
                      {langEn ? 'Register' : '注册'}
                    </h5>
                  </button>
                )}
              </div>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
