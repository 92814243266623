import React, { useContext } from 'react';

import Title from '../Title';
import CartItem from './CartItem';
import CartTotals from './CartTotals';
import productContext from '../../context/productContext';
import authContext from '../../context/authContext';

function Cart() {
  const { langEn } = useContext(authContext);
  const { cart } = useContext(productContext);

  return (
    <>
      <Title title={langEn ? 'cart' : '购物车'} />
      <div className='container-fluid bg-light text-dark pt-3'>
        <div className='row'>
          <div className='col-12 col-lg-6'>
            {cart.map((item) => {
              return <CartItem key={item._id} cartItem={item} />;
            })}
            <div className='col-12'>
              <CartTotals cart={cart} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Cart;
