import React from 'react';

export default function EnrolOrderColumn() {
  return (
    <div className='d-none d-lg-block'>
      <div
        className='row font-weight-bold'
        style={{
          backgroundColor: 'LightSeaGreen',
          color: 'white',
          fontWeight: 'bold',
        }}
      >
        <div className='col-11 col-md-2'>Student/DoB:Gender</div>
        <div className='col-11 col-md-2'>parent Email/Phone</div>
        <div className='col-11 col-md-2'>Booked Timeslots</div>
        <div className='col-11 col-md-2'>PayAmount,Method/Info</div>
        <div className='col-11 col-md-2'>#invoice/note</div>
        <div className='col-11 col-md-1'>Paid/date</div>
        <div className='col-11 col-md-1'>Edit | Del</div>
      </div>
    </div>
  );
}
