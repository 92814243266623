import React, { useContext } from 'react';

import productContext from '../../context/productContext';
import authContext from '../../context/authContext';
import './CartItem.css';

export default function CartItem({ cartItem }) {
  const {
    _id,
    name,
    cnName,
    img,
    price,
    unit,
    cnUnit,
    shelf_pos,
    quantity,
  } = cartItem;

  const { addToCart, removeFromCart, deleteProductFromCart } = useContext(
    productContext
  );
  const { langEn } = useContext(authContext);

  return (
    <div className='row'>
      <div className='col-2'>
        <img
          src={process.env.REACT_APP_ASSET_URL + '/' + img}
          style={{ width: '3rem', height: '3rem' }}
          alt='product'
        />
      </div>
      <div className='col-4 d-flex'>
        <div>
          {shelf_pos}: {langEn ? name : cnName}
          <p className='text-muted'>
            ${price}/{langEn ? unit : cnUnit}
          </p>
        </div>
      </div>

      <div className='col-4'>
        <div>
          <span
            className='btn cart-btn-minus'
            onClick={() => removeFromCart(_id)}
          >
            -
          </span>
          <span className='btn cart-btn-num'>{quantity}</span>
          <span className='btn cart-btn-add' onClick={() => addToCart(_id)}>
            +
          </span>
        </div>
      </div>
      <div className='col-1 text-right'>${(price * quantity).toFixed(2)}</div>
      <div className='col-1'>
        <button
          className='btn cart-icon'
          title='remove this product'
          onClick={() => deleteProductFromCart(_id)}
        >
          <i className='fas fa-trash-alt' />
        </button>
      </div>
    </div>
  );
}
