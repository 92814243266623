import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function UsersEdit({ index, user }) {
  const { _id, fname, lname, email, admin, phone, address } = user;
  const { line1, suburb, state, postcode } = address;

  const { token } = useContext(authContext);
  const [editUser, setEditUser] = useState({
    fname,
    lname,
    email,
    admin,
    phone,
    address: { line1, suburb, state, postcode },
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditUser((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  const handleAddressInput = (event) => {
    const { name, value } = event.target;

    setEditUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/update/' + _id,
        editUser,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-1' : 'row mt-1 bg-white'}>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>Name: </span>
          {fname + ' ' + lname}
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>email: </span>
          {email}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>admin: </span>
          <input
            type='checkbox'
            name='admin'
            checked={editUser.admin}
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>phone: </span>
          <input
            required
            type='text'
            name='phone'
            style={{ width: 90 }}
            value={editUser.phone}
            onChange={handleChange}
          />
        </div>
        <div className='col-11 col-lg-3'>
          <span className='d-lg-none'>address: </span>
          <input
            type='text'
            name='line1'
            style={{ width: 210 }}
            value={editUser.address.line1}
            onChange={handleAddressInput}
          />
          <br></br>
          <input
            type='text'
            name='suburb'
            style={{ width: 100 }}
            value={editUser.address.suburb}
            onChange={handleAddressInput}
          />
          ,{' '}
          <input
            type='text'
            name='state'
            style={{ width: 50 }}
            value={editUser.address.state}
            onChange={handleAddressInput}
          />{' '}
          <input
            type='text'
            name='postcode'
            style={{ width: 50 }}
            value={editUser.address.postcode}
            onChange={handleAddressInput}
          />
        </div>

        <div className='col-11 col-lg-2 text-center'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
