import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../../context/authContext';

export default function SubCatItem({ sindex, scat, setEdit }) {
  const { _id, name, nameCN, icon } = scat;
  const { token } = useContext(authContext);

  async function deleteCategory(productId) {
    const authStr = 'Bearer ' + token;
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/subCats/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={sindex % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>Name: </span>
          {name}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>nameCN: </span>
          {nameCN}
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>icon: </span>
          {icon}
        </div>

        <div className='col-11 col-lg-1'>
          <div className='btn-group' role='group' aria-label='Basic example'>
            <button className='btn btn-link' onClick={() => setEdit(_id)}>
              <i className='fas fa-edit' />
            </button>
            |
            <button
              className='btn btn-link'
              onClick={() => deleteCategory(_id)}
              // disabled
            >
              <i className='fas fa-trash-alt' />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
