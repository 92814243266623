import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Title from '../Title';
import MyEnrolOrderItem from './MyEnrolOrderItem';
import authContext from '../../context/authContext';
import MyEnrolOrderColumn from './MyOrderColumn';

export default function MyEnrolOrder() {
  const { langEn, token } = useContext(authContext);
  const [enrolOrders, setEnrolOrders] = useState([]);

  useEffect(() => {
    async function fetchEnrolOrders() {
      const authStr = 'Bearer ' + token;

      try {
        const res = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/enrolOrders/byemail',
          {
            headers: { Authorization: authStr },
          }
        );
        console.log(res.data);
        setEnrolOrders(res.data);
      } catch (error) {
        console.error(error);
      }
    }
    token && fetchEnrolOrders();
  }, [token]);

  return (
    <>
      <Title title={langEn ? 'My Enrol Orders' : '我的注册订单'} />
      <div className='container-fluid bg-light text-dark'>
        <MyEnrolOrderColumn />
        {enrolOrders.map((eOdr, index) => (
          <MyEnrolOrderItem key={eOdr._id} index={index} enrolOrder={eOdr} />
        ))}
      </div>
    </>
  );
}
