import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
import productContext from '../../context/productContext';

function ProductManageAdd() {
  const { token } = useContext(authContext);
  const { catList, subCats } = useContext(productContext);
  const [newProduct, setNewProduct] = useState({
    name: '',
    cnName: '',
    brand: '',
    cnBrand: '',
    categoryId: '',
    subCatId: '',
    stock: 100,
    price: '',
    includeGST: true,
    onShelf: true,
    unit: '',
    cnUnit: '',
    info: '',
    infoCN: '',
    shelf_pos: '',
  });

  const [productImg, setProductImg] = useState(null);
  const handleSelectedImage = (e) => {
    setProductImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const subCatList = subCats.filter(
    (item) => item.categoryId === newProduct.categoryId
  );

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setNewProduct((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    const uploadProduct = new FormData();
    uploadProduct.append('productImage', productImg);
    // field name 'productImage' must match backend post: upload.single('productImage')

    Object.keys(newProduct).forEach(function (k) {
      uploadProduct.append(k, newProduct[k]);
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/products/add',
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewProduct({
      name: '',
      cnName: '',
      brand: '',
      cnBrand: '',
      categoryId: '',
      subCatId: '',
      stock: 100,
      price: '',
      includeGST: true,
      unit: '',
      cnUnit: '',
      info: '',
      infoCN: '',
      shelf_pos: '',
    });
    setProductImg(null);
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2 bg-white'>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>name: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='name'
            value={newProduct.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='cnName'
            value={newProduct.cnName}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>category: </span>
          <select
            name='categoryId'
            style={{ width: 100 }}
            defaultValue={''}
            onChange={handleChange}
          >
            <option disabled value=''>
              select category
            </option>
            {catList.map((item) => {
              return (
                <option key={item.name} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>sub-Category: </span>
          <select
            name='subCatId'
            style={{ width: 100 }}
            defaultValue={''}
            onChange={handleChange}
          >
            <option disabled value=''>
              select sub-category
            </option>
            {subCatList.map((item) => {
              return (
                <option key={item.name} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className='col-11 col-lg-1 '>
          <span className='d-lg-none'>image: </span>
          <input
            required
            type='file'
            name='img'
            onChange={handleSelectedImage}
          />
        </div>
        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>price: </span>
          <input
            required
            type='number'
            name='price'
            style={{ width: 80 }}
            value={newProduct.price}
            onChange={handleChange}
            placeholder='price'
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>GST: </span>
          <input
            type='checkbox'
            name='includeGST'
            checked={newProduct.includeGST}
            onChange={handleCheck}
          />
        </div>

        <div className='col-11 col-lg-1 text-right'>
          <span className='d-lg-none'>stock: </span>
          <input
            required
            type='number'
            name='stock'
            style={{ width: 80 }}
            value={newProduct.stock}
            onChange={handleChange}
            placeholder='stock'
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input
            type='checkbox'
            name='onShelf'
            checked={newProduct.onShelf}
            onChange={handleCheck}
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>shelf_pos: </span>
          <input
            required
            name='shelf_pos'
            type='text'
            style={{ width: 80 }}
            value={newProduct.shelf_pos}
            onChange={handleChange}
            placeholder='aisle.shelf.level'
          />
        </div>
        <div className='col-11 mx-atuo col-lg-1'>
          <span className='d-lg-none'>Save: </span>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
            <span>+</span>
          </button>
        </div>
      </div>

      <div className='row bg-white'>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>unit: </span>
          <input
            name='unit'
            type='text'
            style={{ width: 80 }}
            value={newProduct.unit}
            onChange={handleChange}
            placeholder='unit'
          />
        </div>
        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>单元: </span>
          <input
            type='text'
            style={{ width: 80 }}
            name='cnUnit'
            value={newProduct.cnUnit}
            onChange={handleChange}
            placeholder='单元'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <input
            name='brand'
            type='text'
            style={{ width: 80 }}
            value={newProduct.brand}
            onChange={handleChange}
            placeholder='Brand:'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <input
            type='text'
            name='cnBrand'
            style={{ width: 80 }}
            value={newProduct.cnBrand}
            onChange={handleChange}
            placeholder='品牌'
          />
        </div>

        <div className='col-11 col-lg-2'>
          <input
            name='info'
            type='text'
            style={{ width: 200 }}
            value={newProduct.info}
            onChange={handleChange}
            placeholder='Product Info'
          />
        </div>
        <div className='col-11 col-lg-2'>
          <input
            type='text'
            name='infoCN'
            style={{ width: 200 }}
            value={newProduct.infoCN}
            onChange={handleChange}
            placeholder='商品信息'
          />
        </div>
      </div>
    </form>
  );
}
export default ProductManageAdd;
