import React, { useState } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';

// Source: https://itnext.io/password-reset-emails-in-your-react-app-made-easy-with-nodemailer-bb27968310d7

export default function ForgotPassword({ emailInput, setShowAuthModal }) {
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);

  const sendEmail = async (event) => {
    event.preventDefault();
    setSendingEmail(true);
    try {
      const responseData = await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/forgotPassword',
        {
          email: emailInput,
          resetPasswordLink:
            process.env.REACT_APP_FRONTEND_URL + '/user/resetPassword/',
        }
      );
      if (responseData.status === 200) {
        setEmailSent(true);
      }
    } catch (error) {
      console.log(error.response);
    }
    setSendingEmail(false);
  };

  return (
    <div>
      <p>
        Please try enter password{' '}
        <span role='img' aria-label='above'>
          👆
        </span>{' '}
        again.
      </p>
      <p>
        If you forgot your password, you may request a password reset by
        clicking on the{' '}
        <span style={{ backgroundColor: 'yellow' }}>Reset Password</span> button
        below.
      </p>
      <p>We will then send a password reset email to you.</p>
      {sendingEmail && (
        <div>
          <Spinner animation='border' />
          sending password reset email to: {emailInput} ...
        </div>
      )}

      {emailSent ? (
        <div style={{ backgroundColor: 'PaleGreen' }}>
          <p>Recovery email sent. Please check your email.</p>
          <p>
            Note: the Password Reset Email may be in your{' '}
            <span style={{ backgroundColor: 'yellow' }}>Spam Email</span> or{' '}
            <span style={{ backgroundColor: 'yellow' }}>Junk Email</span>{' '}
            folder.
          </p>
          <div className='d-flex'>
            <Link
              to='/'
              className='btn btn-success ml-auto'
              onClick={() => setShowAuthModal(false)}
            >
              Return
            </Link>
          </div>
        </div>
      ) : (
        <div className='d-flex'>
          <button className='btn btn-warning ml-auto' onClick={sendEmail}>
            Reset Password
          </button>
        </div>
      )}
    </div>
  );
}
