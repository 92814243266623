import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function CategoryAdd() {
  const { token } = useContext(authContext);
  const [newCategory, setNewCategory] = useState({
    name: '',
    nameCN: '',
    catID: 99,
    icon: '',
    note: '',
    subCat: [],
  });

  const [catImg, setCatImg] = useState(null);
  const handleSelectedImage = (e) => {
    setCatImg(e.target.files[0]);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewCategory((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    const uploadCat = new FormData();
    uploadCat.append('catImage', catImg);
    Object.keys(newCategory).forEach(function (k) {
      uploadCat.append(k, newCategory[k]);
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/categories/add',
        uploadCat,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewCategory({
      name: '',
      nameCN: '',
      catID: 99,
      icon: '',
      note: '',
      subCat: [],
    });
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2 bg-white'>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>Category: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='name'
            value={newCategory.name}
            onChange={handleChange}
            placeholder='name'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          <input
            type='text'
            style={{ width: 80 }}
            name='nameCN'
            value={newCategory.nameCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>ID: </span>
          <input
            type='number'
            style={{ width: 80 }}
            name='catID'
            value={newCategory.catID}
            onChange={handleChange}
            placeholder='catID'
          />
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>icon: </span>
          <input
            type='text'
            name='icon'
            style={{ width: 80 }}
            value={newCategory.icon}
            onChange={handleChange}
            placeholder='icon'
          />
        </div>

        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>image: </span>
          <input type='file' name='img' onChange={handleSelectedImage} />
        </div>

        <div className='col-11 col-lg-4'>
          <span className='d-lg-none'>note: </span>
          <input
            type='text'
            name='note'
            style={{ width: 400 }}
            value={newCategory.note}
            onChange={handleChange}
            placeholder='note'
          />
        </div>

        <div className='col-11 mx-atuo col-lg-1'>
          <span className='d-lg-none'>Save: </span>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />+
          </button>
        </div>
      </div>
    </form>
  );
}
