import React from 'react';
import { Link } from 'react-router-dom';

import Title from '../Title';

export default function EnrolFormConfirm({ orderConfirm, parentName }) {
  const {
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    // payInfo,
    receipt,
    submitDate,
  } = orderConfirm;
  return (
    <>
      <Title bg_img='/img/title_enrol.png' title='Enrolment Request' />

      <div className='row'>
        <div className='col-md-3'></div>
        <div className='col-md-6'>
          <div
            className='row p-3 my-3'
            style={{
              color: 'black',
              backgroundColor: '#f3f3f3',
              boxShadow: '1px 1px',
            }}
          >
            <div className='col-12'>
              <h3>Invoice #{receipt}</h3>
            </div>
            <div className='col-4'>Student:</div>
            <div className='col-8'>{student}</div>
            <div className='col-4'>DoB/Gender:</div>
            <div className='col-8'>
              {DoB} / {gender}
            </div>

            <div className='col-4'>Parent:</div>
            <div className='col-8'>{parentName}</div>
            <div className='col-4'>Email/Phone:</div>
            <div className='col-8'>
              {parentEmail} / {parentPhone}
            </div>

            <div className='col-4'>Class:</div>
            <div className='col-8'>{selSlots.join(', ')}</div>
            <div className='col-4'>Payment:</div>
            <div className='col-8'>
              ${payAmount} by {payMethod}
            </div>
            <div className='col-4'>Invoice:</div>
            <div className='col-8'>#{receipt}</div>
            <div className='col-4'>Request Date:</div>
            <div className='col-8'>
              {new Date(submitDate).toLocaleDateString()}
            </div>
            <div className='col-12'>
              <br />
              <p>
                Please pay at Infinity Art School to secure your place in your
                chosen class.
              </p>
            </div>
            <div className='col-6'></div>
            <div className='col-2'>
              <button
                className='btn btn-primary'
                onClick={() => window.print()}
              >
                Print
              </button>
            </div>
            <div className='col-2'>
              <Link to='/' className='btn btn-success align-content-end'>
                Return
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
