import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import authContext from '../../context/authContext';
import Title from '../Title';

export default function UserUpdate() {
  const { langEn, token } = useContext(authContext);
  const history = useHistory();
  const [updateUser, setUpdateUser] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
    phone: '',
    address: { line1: '', line2: '', suburb: '', state: '', postcode: '' },
  });

  useEffect(() => {
    const fetchUser = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/findbytoken',
          {
            headers: { Authorization: authStr },
          }
        );

        setUpdateUser(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    token && fetchUser();
  }, [token]);

  const handleStreetInput = (event) => {
    const { name, value } = event.target;

    setUpdateUser((prevState) => {
      return {
        ...prevState,
        address: {
          ...prevState.address,
          [name]: value,
        },
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    const authStr = 'Bearer ' + token;
    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/users/update/self',
        updateUser,
        {
          headers: { Authorization: authStr },
        }
      );
      history.push('/');
    } catch (error) {
      console.error(error.response.data.message);
    }
  }

  return (
    <div className='container'>
      <Title title={langEn ? 'Personal Information' : '个人信息'} />

      <div className='card' style={{ width: '25rem' }}>
        <div className='card-header'>
          <div className='row'>
            <div className='col-12'>
              <h5>
                Name: {updateUser.fname} {updateUser.lname}
              </h5>
            </div>
            <div className='col-md-6'>Email: {updateUser.email}</div>
            <div className='col-md-6'>Phone: {updateUser.phone}</div>
          </div>
        </div>
        <div className='card-body'>
          <form onSubmit={handleSubmit}>
            <div className='form-row'>
              <div className='form-group col-12'>
                <label>Street Address: </label>
                <input
                  className='form-control'
                  type='text'
                  name='line1'
                  value={updateUser.address.line1}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-6'>
                <label>Suburb: </label>
                <input
                  className='form-control'
                  type='text'
                  name='suburb'
                  value={updateUser.address.suburb}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>State: </label>
                <input
                  className='form-control'
                  type='text'
                  name='state'
                  value={updateUser.address.state}
                  onChange={handleStreetInput}
                />
              </div>
              <div className='form-group col-md-3'>
                <label>Post code: </label>
                <input
                  className='form-control'
                  type='text'
                  name='postcode'
                  value={updateUser.address.postcode}
                  onChange={handleStreetInput}
                />
              </div>
            </div>
            <div className='card-footer text-right'>
              <button className='btn btn-primary' type='submit'>
                <span role='img' aria-label='shop'>
                  💾 Save Changes
                </span>
              </button>{' '}
              <button
                className='btn btn-outline-primary'
                onClick={() => history.push('/')}
              >
                <span role='img' aria-label='shop'>
                  🏠 Back to Shop
                </span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
