import React, { useState, useContext } from 'react';
import axios from 'axios';
import Collapse from 'react-bootstrap/Collapse';

import authContext from '../../context/authContext';
import productContext from '../../context/productContext';
import SubCatManage from './SubCat/SubCatManage';

export default function CategoeyItem({ index, cat, setEdit }) {
  const { _id, name, nameCN, catID, icon, note, img } = cat;
  const { token } = useContext(authContext);
  const { subCats } = useContext(productContext);

  const [openSubCat, setOpenSubCat] = useState(false);

  const subCat = subCats.filter((item) => item.categoryId === _id);

  async function deleteCategory(productId) {
    const authStr = 'Bearer ' + token;
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/categories/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>Name: </span>
          {name}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>nameCN: </span>
          {nameCN}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>catID: </span>
          {catID}
        </div>

        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>icon: </span>
          {icon}
        </div>
        <div className='col-11 col-lg-2 text-center'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + img}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='product'
          />
        </div>
        <div className='col-11 col-lg-4'>
          <span className='d-lg-none'>note: </span>
          {note}
        </div>

        <div className='col-11 col-lg-1'>
          <div className='btn-group' role='group' aria-label='Basic example'>
            <button className='btn btn-link' onClick={() => setEdit(_id)}>
              <i className='fas fa-edit' />
            </button>
            |
            <button
              className='btn btn-link'
              onClick={() => deleteCategory(_id)}
              // disabled
            >
              <i className='fas fa-trash-alt' />
            </button>
          </div>
        </div>
        <div className='container-fluid row'>
          <div className='col-1'></div>
          <div className='col-11 col-lg-2'>
            <button
              className='btn btn-link'
              onClick={() => setOpenSubCat(!openSubCat)}
            >
              Sub-Categories▼
            </button>
          </div>
        </div>

        <Collapse in={openSubCat}>
          <div className='container-fluid row'>
            <div className='col-1'></div>
            <div className='col-10'>
              <SubCatManage subCat={subCat} categoryId={_id} />
            </div>
          </div>
        </Collapse>
      </div>
    </>
  );
}
