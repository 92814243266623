import React from 'react';

export default function ProductManageColumns() {
  return (
    <div className='text-center d-none d-lg-block'>
      <div className='row'>
        <div className='col-11 mx-auto col-lg-1'>name</div>
        <div className='col-11 mx-auto col-lg-1'>名称</div>
        <div className='col-11 mx-auto col-lg-1'>Category</div>
        <div className='col-11 mx-auto col-lg-1'>sub-Catetory</div>
        <div className='col-11 mx-auto col-lg-1'>image</div>
        <div className='col-11 mx-auto col-lg-1'>price</div>
        <div className='col-11 mx-auto col-lg-1'>GST</div>
        <div className='col-11 mx-auto col-lg-1'>stock</div>
        <div className='col-11 mx-auto col-lg-1'>onShelf</div>
        <div className='col-11 mx-auto col-lg-1'>aisle.shelf.level</div>
        <div className='col-11 mx-auto col-lg-1'>save/edit</div>
        <div className='col-11 mx-auto col-lg-1'></div>
      </div>
    </div>
  );
}
