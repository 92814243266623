import { createContext } from 'react';

const authContext = createContext({
  langEn: true,
  setLang: () => {},
  token: '',
  userId: '',
  userName: '',
  admin: false,
  login: () => {},
  logout: () => {},
});

export default authContext;
