import React, { useEffect, useState } from "react";
import Axios from "axios";

export default function EnrolFormPayment({ enrolSheet, setEnrolSheet }) {
  const [enrolDatePrice, setEnrolDatePrice] = useState({
    earlyBirdDate: "",
    normalPrice1T: 0,
    earlyBird1T: 0,
    normalPrice2T: 0,
    earlyBird2T: 0,
  });

  useEffect(() => {
    let isMount = true;
    async function fetchEnrolDatePrice() {
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/enrolDatePrice/`
        );
        if (isMount) setEnrolDatePrice(res.data[0]);
      } catch (error) {
        console.error(error);
      }
    }
    fetchEnrolDatePrice();
    return () => {
      isMount = false;
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEnrolSheet((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  return (
    <fieldset className="form-group required col-md-4" onChange={handleChange}>
      <div className="row">
        <div className="col-8 p-2">
          <legend className="col-form-label control-label">
            <b>Payment</b>
          </legend>
          $
          <input
            type="number"
            required
            style={{ width: 100 }}
            name="payAmount"
            value={enrolSheet.payAmount}
            onChange={handleChange}
          />
          <br />
          <br />
          <label>
            <b>Voucher Number:</b>
          </label>
          <input
            type="text"
            className="form-control"
            style={{ maxWidth: 200 }}
            name="payInfo"
            value={enrolSheet.payInfo}
            onChange={handleChange}
            // placeholder='PayRef:child first name'
            placeholder="9999 XXXX XXXX XXXX"
          />
        </div>
        <div className="col-4" style={{ position: "relative" }}>
          <div style={{ position: "absolute", bottom: "0" }}>
            <a
              href="https://www.service.nsw.gov.au/transaction/apply-creative-kids-voucher"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="img/img_creativeKids.png"
                width="100%"
                alt="creative kids"
              />
            </a>
          </div>
        </div>
        <div className="">
          Apply for a{" "}
          <a
            href="https://www.service.nsw.gov.au/transaction/apply-creative-kids-voucher"
            target="_blank"
            rel="noopener noreferrer"
          >
            Creative Kids Voucher
          </a>{" "}
          to receive $50 discount. Infinity Art School is a Registered Provider
          of the{" "}
          <a
            href="https://www.service.nsw.gov.au/campaign/creative-kids"
            target="_blank"
            rel="noopener noreferrer"
          >
            NSW Creative Kids Program.
          </a>
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <td rowSpan="2">
              ONE TERM <br />
              (9 lessons)
            </td>
            <td
              style={{
                borderRight: "none",
              }}
            >
              Normal Price:
            </td>
            <td
              style={{
                borderLeft: "none",
              }}
            >
              ${enrolDatePrice.normalPrice1T}
            </td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: "lightgreen",
                borderRight: "none",
              }}
            >
              {/* Early Bird Price<sup>*</sup>: */}
              Discount Price<sup>*</sup>:
            </td>
            <td
              style={{
                backgroundColor: "lightgreen",
                borderLeft: "none",
              }}
            >
              ${enrolDatePrice.earlyBird1T}
            </td>
          </tr>
          <tr>
            <td rowSpan="2">
              TWO TERMs <br />
              (18 lessons)
            </td>
            <td
              style={{
                borderRight: "none",
              }}
            >
              Normal Price:
            </td>
            <td
              style={{
                borderLeft: "none",
              }}
            >
              ${enrolDatePrice.normalPrice2T}
            </td>
          </tr>
          <tr>
            <td
              style={{
                backgroundColor: "lightgreen",
                borderRight: "none",
              }}
            >
              {/* Early Bird Price<sup>*</sup>: */}
              Discount Price<sup>*</sup>:
            </td>
            <td
              style={{
                backgroundColor: "lightgreen",
                borderLeft: "none",
              }}
            >
              ${enrolDatePrice.earlyBird2T}
            </td>
          </tr>
          <tr>
            <td colSpan="3" style={{ backgroundColor: "lightgreen" }}>
              {/* <sup>*</sup>Early Bird Price: pay before{' '}
                          {enrolDatePrice.earlyBirdDate.substr(0, 10)} */}
              <sup>*</sup>Discount Price: Provide{" "}
              <a
                href="https://www.service.nsw.gov.au/transaction/apply-creative-kids-voucher"
                target="_blank"
                rel="noopener noreferrer"
              >
                Creative Kids Voucher
              </a>{" "}
              to receive $50 discount.
            </td>
          </tr>
        </tbody>
      </table>

      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="payMethod"
          id="gridRadios1"
          value="Cash"
        />
        <label className="form-check-label" htmlFor="gridRadios1">
          Cash <small>(Pay at the counter)</small>
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="payMethod"
          id="gridRadios2"
          value="Cheque"
        />
        <label className="form-check-label" htmlFor="gridRadios2">
          Cheque <small>(payable to Infinity Art School)</small>
        </label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          name="payMethod"
          id="gridRadios3"
          value="Bank Transfer"
        />
        <label className="form-check-label" htmlFor="gridRadios3">
          Bank Transfer <br />
          <small>ACC Name: Shengqian Lu</small> <br />
          <small>BSB 012-287; ACC#: 291 333 293</small>
        </label>
        {/* <input
                      type='text'
                      className='form-control'
                      style={{ maxWidth: 300 }}
                      name='payInfo'
                      value={enrolSheet.payInfo}
                      onChange={handleChange}
                      placeholder='PayRef:child first name'
                    /> */}
      </div>
      <b>Submit Online:</b>
      <button type="submit" className="btn btn-success m-3">
        Submit
      </button>
    </fieldset>
  );
}
