import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
import OrdersByUserModal from '../Order/OrdersByUserModal';

export default function UsersItem({ index, user, setEdit }) {
  const { _id, fname, lname, email, admin, phone, address } = user;

  const { token } = useContext(authContext);

  async function deleteUser(productId) {
    const authStr = 'Bearer ' + token;
    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/users/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-1' : 'row mt-1 bg-white'}>
        <div className='col-11 col-lg-2 text-left'>
          <span className='d-lg-none'>Name: </span>
          {fname + ' ' + lname}
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>email: </span>
          {email}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>admin: </span>
          <input type='checkbox' checked={admin} disabled />
        </div>
        <div className='col-11 col-lg-2'>
          <span className='d-lg-none'>phone: </span>
          {phone}
        </div>
        <div className='col-11 col-lg-3'>
          <span className='d-lg-none'>address: </span>
          {address.line1}
          <br></br>
          {address.suburb}, {address.state} {address.postcode}
        </div>

        <div className='col-11 col-lg-2'>
          <div className='btn-group' role='group' aria-label='Basic example'>
            <OrdersByUserModal userId={_id} token={token} /> |
            <button className='btn btn-link' onClick={() => setEdit(_id)}>
              <i className='fas fa-edit' />
            </button>
            |
            {!admin && (
              <button
                className='btn btn-link'
                onClick={() => deleteUser(_id)}
                // disabled
              >
                <i className='fas fa-trash-alt' />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
