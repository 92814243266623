import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function ManageStuArtItem({ index, stuArt, setEdit }) {
  const {
    _id,
    author,
    title,
    titleCN,
    category,
    imgPreview,
    onShelf,
    // info,
    // infoCN,
  } = stuArt;

  const { token } = useContext(authContext);

  async function deleteProd(productId) {
    const authStr = 'Bearer ' + token;
    // const uploadProduct = new FormData();
    // uploadProduct.append('productImage', productImg);
    // // field name 'productImage' must match backend post: upload.single('productImage')

    // Object.keys(editProduct).forEach(function (k) {
    //   uploadProduct.append(k, editProduct[k]);
    // });

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/stuarts/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>title: </span>
          {title}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          {titleCN}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>author: </span>
          {author}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>category: </span>
          {category}
        </div>
        <div className='col-11 col-lg-2 text-center'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + imgPreview}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='stuArt'
          />
        </div>

        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input type='checkbox' checked={onShelf} disabled />
        </div>
        {/* <div className='col-11  col-lg-2'>
          {info && info.substring(0, 20) + '...'}
        </div>
        <div className='col-11  col-lg-2'>
          {infoCN && infoCN.substring(0, 8) + '......'}
        </div> */}

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' onClick={() => setEdit(_id)}>
            <i className='fas fa-edit' />
          </button>
          {!onShelf && (
            <button className='btn btn-link' onClick={() => deleteProd(_id)}>
              <i className='fas fa-trash-alt' />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
