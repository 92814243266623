import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Title from '../Title';
import ManageStuArtColumns from './ManageStuArtColumns';
import ManageStuArtItem from './ManageStuArtItem';
import ManageStuArtAdd from './ManageStuArtAdd';
import ManageStuArtEdit from './ManageStuArtEdit';
import authContext from '../../context/authContext';

export const stuArtCat = [
  'Oil Painting',
  'Portrait',
  'Watercolour',
  'Pen(cil) Sketch',
  'Handcraft',
];

export default function ManageStuArts() {
  const { langEn } = useContext(authContext);
  const [stuArts, setStuArts] = useState([]);

  useEffect(() => {
    const fetchStuArts = async () => {
      try {
        const resProd = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/stuarts/all'
        );

        setStuArts([...resProd.data]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStuArts();
  }, []);

  const [editProductId, setEditProductId] = useState('');
  const setEdit = (productId) => {
    setEditProductId(productId);
  };

  return (
    <div className='container-fluid'>
      <Title title={langEn ? 'Product Management' : '商品管理'} />
      <div className='container-fluid bg-light text-dark'>
        <ManageStuArtColumns />
        <ManageStuArtAdd stuArtCat={stuArtCat} />
        {stuArts.map((stuArt, index) => {
          if (stuArt._id === editProductId) {
            return (
              <ManageStuArtEdit
                key={stuArt._id}
                index={index}
                stuArt={stuArt}
              />
            );
          } else {
            return (
              <ManageStuArtItem
                key={stuArt._id}
                index={index}
                stuArt={stuArt}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
