import React, { useState, useContext } from 'react';

import Title from '../Title';
import ProductManageColumns from './ProductManageColumns';
import ProductManageItem from './ProductManageItem';
import ProductManageAdd from './ProductManageAdd';
import ProductManageEdit from './ProductManageEdit';
import authContext from '../../context/authContext';
import productContext from '../../context/productContext';

export default function ProductManage() {
  const { langEn } = useContext(authContext);
  const { products } = useContext(productContext);

  const [editProductId, setEditProductId] = useState('');
  const setEdit = (productId) => {
    setEditProductId(productId);
  };

  return (
    <div className='container-fluid'>
      <Title title={langEn ? 'Manage Product' : '商品管理'} />
      <div className='container-fluid bg-light text-dark'>
        <ProductManageColumns />
        <ProductManageAdd />
        {products.map((product, index) => {
          if (product._id === editProductId) {
            return (
              <ProductManageEdit
                key={product._id}
                index={index}
                product={product}
              />
            );
          } else {
            return (
              <ProductManageItem
                key={product._id}
                index={index}
                product={product}
                setEdit={setEdit}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
