import React from 'react';

export default function SubCatColumns() {
  return (
    <div className='row'>
      <div className='col-11 col-lg-1'>sub-Cat</div>
      <div className='col-11 col-lg-1'>子类别</div>
      <div className='col-11 col-lg-1'>icon</div>

      <div className='col-11 col-lg-2'>edit | delete</div>
    </div>
  );
}
