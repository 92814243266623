import React, { useState, useContext, useEffect, useCallback } from 'react';
import Collapse from 'react-bootstrap/Collapse';

import ProductItem from './ProductItem';
import './Product.css';
import productContext from '../../context/productContext';
import authContext from '../../context/authContext';

export default function ProductSearch() {
  const { onShelfProducts } = useContext(productContext);
  const { langEn } = useContext(authContext);

  const [filteredProducts, setFilteredProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [openBrands, setOpenBrands] = useState(false);

  useEffect(() => {
    // setFilteredProducts(onShelfProducts);
    setFilteredProducts([]);

    let _brands = [];
    onShelfProducts.forEach((item) => {
      // find all defined brands without duplicate
      if (
        typeof item.brand !== 'undefined' &&
        item.brand !== null &&
        item.brand !== '' &&
        !_brands.some((e) => e.name === item.brand)
      ) {
        _brands.push({
          name: item.brand,
          cnName: item.cnBrand,
          isChecked: false,
        });
      }
    });
    _brands.sort((a, b) => {
      if (a.name.toLowerCase() >= b.name.toLowerCase()) {
        return 1;
      } else {
        return -1;
      }
    });
    setBrands([..._brands]);
  }, [onShelfProducts]);

  const handleSearch = (e) => {
    let currList = [];
    let newList = [];
    if (e.target.value !== '') {
      currList = onShelfProducts;
      newList = currList.filter((item) => {
        const lcName = langEn ? item.name.toLowerCase() : item.cnName;
        const searchStr = e.target.value.toLowerCase();
        return lcName.includes(searchStr);
      });
    } else {
      // newList = onShelfProducts;
    }
    setFilteredProducts([...newList]);
  };

  const handleCheck = useCallback(
    (e) => {
      const index = e.target.name;
      let _brandsTemp = [...brands];
      _brandsTemp[index].isChecked = e.target.checked;
      setBrands(_brandsTemp);

      let _checkedBrands = [];
      _brandsTemp.forEach((brand) => {
        if (brand.isChecked) {
          _checkedBrands.push(brand.name);
        }
      });
      let newList = [];
      if (_checkedBrands.length > 0) {
        newList = onShelfProducts.filter((item) => {
          return _checkedBrands.includes(item.brand);
        });
      } else {
        // newList = [...onShelfProducts];
      }
      setFilteredProducts([...newList]);
    },
    [brands, onShelfProducts]
  );

  return (
    <div className='container-fluid'>
      <div className='row'>
        <div className='col-11 col-lg-1'>
          <label className='search-label' htmlFor='search-input'>
            <input
              type='text'
              id='search-input'
              onChange={handleSearch}
              placeholder={langEn ? '🔍Search...' : '🔍检索...'}
            />
          </label>

          <button
            className='btn btn-link px-1'
            onClick={() => setOpenBrands(!openBrands)}
            aria-controls='brands'
            aria-expanded={openBrands}
          >
            <h5>▼{langEn ? 'Brands' : '品牌'}</h5>
          </button>
          <Collapse in={openBrands}>
            <div id='brands'>
              {brands.map((item, index) => {
                return (
                  <div className='form-check' key={index}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      key={index}
                      name={index}
                      checked={item.isChecked}
                      onChange={handleCheck}
                      id='defaultCheck1'
                    />
                    <label className='form-check-label' htmlFor='defaultCheck1'>
                      {langEn ? item.name : item.cnName}
                    </label>
                  </div>
                );
              })}
            </div>
          </Collapse>
        </div>
        {filteredProducts.length < 1 && (
          <div className='col-lg-11'>
            <h3>
              <span role='img' aria-label='search'>
                👈 {langEn ? 'Search Products' : '商品检索'}
              </span>
            </h3>
            <h3>
              <span role='img' aria-label='search'>
                👈 {langEn ? 'Select Brands' : '选择品牌'}
              </span>
            </h3>
          </div>
        )}
        <div className='col-lg-11'>
          <div className='container-fluid row'>
            {filteredProducts.map((product) => {
              return <ProductItem key={product._id} product={product} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
