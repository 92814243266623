import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';

import Title from '../Title';
import authContext from '../../context/authContext';
import { stuArtCat } from './ManageStuArts';
import StuArtGalleryModal from './StuArtGalleryModal';

export default function Gallery() {
  const { langEn } = useContext(authContext);

  const [stuArts, setStuArts] = useState([]);

  useEffect(() => {
    const fetchStuArts = async () => {
      try {
        const resProd = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/stuarts/all'
        );

        setStuArts([...resProd.data.filter((item) => item.onShelf)]);
      } catch (error) {
        console.error(error);
      }
    };
    fetchStuArts();
  }, []);

  return (
    <>
      <Title
        bg_img='/img/title_gallery.png'
        title={langEn ? <span>Student Artworks Gallery</span> : '画廊'}
      />

      <hr />

      {stuArtCat.map((cat, index) => (
        <div key={index}>
          <h3 className='text-center'>{cat}</h3>
          <div className='container-fluid'>
            <div className='row px-2'>
              {stuArts
                .filter((item) => item.category === cat)
                .map((artWork, index) => (
                  <div
                    className='card bg-light text-white col-lg-2 col-md-4 col-6 p-0'
                    key={index}
                  >
                    <img
                      className='card-img'
                      src={
                        process.env.REACT_APP_ASSET_URL +
                        '/' +
                        artWork.imgPreview
                      }
                      alt='artWork'
                      height={
                        artWork.category === 'Portrait' ? '280px' : '200px'
                      }
                    />

                    <div className='card-img-overlay'>
                      <h5 className='card-title'>
                        {langEn ? artWork.title : artWork.titleCN}
                      </h5>
                      <p className='card-text'>by {artWork.author}</p>
                      <p className='card-footer'>
                        <StuArtGalleryModal artWork={artWork} />
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
    </>
  );
}
