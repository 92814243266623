import React, { useContext } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';

import authContext from '../context/authContext';
import productContext from '../context/productContext';
import './ShopNavbar.css';
import AuthModal from './User/AuthModal';

function ShopNavbar() {
  const { token, admin, logout, langEn, setLang } = useContext(authContext);
  const { cart } = useContext(productContext);

  return (
    <Navbar collapseOnSelect expand='md' variant='dark' sticky='top'>
      <Navbar.Toggle aria-controls='responsive-navbar-nav' />

      <Navbar.Brand href='/'>
        <img
          src={'/logo_infArt.png'}
          alt='infArt logo'
          style={{ width: '2rem' }}
        />{' '}
        <span>Infinity Art</span>
      </Navbar.Brand>

      <Navbar.Collapse id='responsive-navbar-nav'>
        <Nav>
          <Nav.Link href='/aboutus'>
            <span role='img' aria-label='address'>
              👨🏼‍🤝‍👨🏻 {langEn ? 'About' : '关于'}
            </span>
          </Nav.Link>
          <Nav.Link href='/enrolform'>
            <span role='img' aria-label='address'>
              🙋‍♀️ {langEn ? 'Enrol' : '注册'}
            </span>
          </Nav.Link>
          <Nav.Link href='/gallery/show'>
            <span role='img' aria-label='address'>
              🖼 {langEn ? 'Gallery' : '画廊'}
            </span>
          </Nav.Link>
          <Nav.Link href='/products/all'>
            <span role='img' aria-label='address'>
              🛍 {langEn ? 'Shop' : '商店'}
            </span>
          </Nav.Link>
          <Nav.Link href='/contact'>
            <span role='img' aria-label='address'>
              ✍️ {langEn ? 'Contact' : '联系'}
            </span>
          </Nav.Link>

          {/* {catList.map((item) => {
              return (
                <Nav.Link key={item.name} href={`/productsbycat/${item.name}`}>
                  {item.icon} {langEn ? item.name : item.nameCN}
                </Nav.Link>
              );
            })} */}
        </Nav>

        {/* <Nav.Link href={'/products/search'}>
            <span role='img' aria-label='search'>
              🔍 {langEn ? 'Search' : '检索'}
            </span>
          </Nav.Link> */}

        <Nav.Link className='ml-auto' href='/cart'>
          <span style={{ fontSize: '1.2rem', color: '#00ff00' }}>
            <i className='fas fa-shopping-cart' />
          </span>
          <span className='badge badge-warning' id='lblCartCount'>
            {cart ? cart.reduce((count, item) => count + item.quantity, 0) : 0}
          </span>
        </Nav.Link>

        <button
          className='btn btn-sm btn-link p-0 mr-2'
          title='set language'
          style={{ fontSize: '2rem' }}
          onClick={setLang}
        >
          {langEn ? (
            <span role='img' aria-label='lang'>
              🇦🇺
            </span>
          ) : (
            <span role='img' aria-label='lang'>
              🇨🇳
            </span>
          )}
        </button>
      </Navbar.Collapse>

      {token ? (
        <>
          {admin ? (
            <NavDropdown
              alignRight
              title={
                <span className='text-danger'>
                  <i className='fas fa-user-cog'></i>
                  <span className='d-none d-md-inline'>
                    {langEn ? ' Manager' : ' 管理员'}
                  </span>
                </span>
              }
              id='basic-nav-dropdown'
            >
              <NavDropdown.Item href='/enrol/manage' className='text-primary'>
                {langEn ? 'Manage enrolSlots' : '课时管理'}
              </NavDropdown.Item>
              <NavDropdown.Item href='/enrol/orders' className='text-primary'>
                {langEn ? 'Manage enrolOrders' : '课时订单管理'}
              </NavDropdown.Item>
              <NavDropdown.Item href='/stuarts/manage' className='text-primary'>
                {langEn ? 'Manage student artworks' : '学生艺术品管理'}
              </NavDropdown.Item>
              <NavDropdown.Item
                href='/category/manage'
                className='text-success'
              >
                {langEn ? 'Manage product categories' : '商品类别管理'}
              </NavDropdown.Item>
              <NavDropdown.Item href='/product/manage' className='text-success'>
                {langEn ? 'Manage products' : '商品管理'}
              </NavDropdown.Item>
              <NavDropdown.Item href='/order/manage' className='text-success'>
                {langEn ? 'Manage product orders' : '商品订单管理'}
              </NavDropdown.Item>
              <NavDropdown.Item href='/user/manage' className='text-warning'>
                {langEn ? 'Manage users' : '用户管理'}
              </NavDropdown.Item>
              <NavDropdown.Item
                href='/'
                onClick={logout}
                className='text-warning'
              >
                {langEn ? 'Logout ' : '退出 '}
                <i className='fas fa-sign-out-alt'></i>
              </NavDropdown.Item>
            </NavDropdown>
          ) : (
            <NavDropdown
              alignRight
              title={
                <span className='text-success'>
                  <i className='fas fa-user'></i>
                  <span className='d-none d-md-inline'>
                    {langEn ? ' MyAcct' : ' 我的账户'}
                  </span>
                </span>
              }
            >
              <NavDropdown.Item href='/enrol/myorders'>
                <span role='img' aria-label='order'>
                  🧾 {langEn ? 'MyEnrolOrders' : '我的注册订单'}
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item href='/user/update'>
                <span role='img' aria-label='address'>
                  🏠 {langEn ? 'MyAddress' : '我的地址'}
                </span>
              </NavDropdown.Item>
              <NavDropdown.Item
                className='text-warning'
                href='/'
                onClick={logout}
              >
                {langEn ? 'Logout ' : '退出 '}
                <i className='fas fa-sign-out-alt'></i>
              </NavDropdown.Item>
            </NavDropdown>
          )}
        </>
      ) : (
        <AuthModal />
      )}
    </Navbar>
  );
}

export default ShopNavbar;
