import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import authContext from '../../context/authContext';
import AuthModal from '../User/AuthModal';

export default function CartTotals({ cart }) {
  const { langEn, token } = useContext(authContext);

  const cartCostTotal = cart.reduce(
    (cost, item) => cost + item.price * item.quantity,
    0
  );
  const cartGSTTotal = cart.reduce(
    (gst, item) =>
      gst + (item.includeGST ? item.price / 11 : 0) * item.quantity,
    0
  );

  return (
    <div className='text-right'>
      <div>
        <h5>
          <span>
            {langEn ? 'Total' : '总计'} ${cartCostTotal.toFixed(2)}
          </span>
        </h5>
        <span className='text-muted'>
          {langEn ? 'Incl. GST' : '含消费税'}: ${cartGSTTotal.toFixed(2)}
        </span>
      </div>
      <Link to='/products/all'>
        <button className='btn btn-outline-primary mr-2'>
          <h5>
            <i className='fas fa-store'></i>
            <span>{langEn ? 'Back to Shop' : '回到商店'}</span>
          </h5>
        </button>
      </Link>
      {cart.length > 0 && token ? (
        <Link to='/cartcheckout'>
          <button className='btn btn-success'>
            <h5>
              <i className='fas fa-cash-register'></i>
              <span>{langEn ? 'Check Out' : '结账收银'}</span>
            </h5>
          </button>
        </Link>
      ) : (
        <>
          <AuthModal />
          <p className='text-muted'>Login to buy</p>
        </>
      )}
    </div>
  );
}
