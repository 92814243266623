import React from 'react';

export default function ProductManageColumns() {
  return (
    <div className='row font-weight-bold'>
      <div className='col-11 col-lg-1'>title</div>
      <div className='col-11 col-lg-1'>名称</div>
      <div className='col-11 col-lg-1'>author</div>
      <div className='col-11 col-lg-1'>Category</div>
      <div className='col-11 col-lg-2'>imagePreview</div>
      <div className='col-11 col-lg-1'>onShelf</div>
      {/* <div className='col-11 col-lg-2'>info</div>
      <div className='col-11 col-lg-2'>infoCN</div> */}

      <div className='col-11 col-lg-1'>save/edit</div>
    </div>
  );
}
