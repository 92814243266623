import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function ManageStuArtAdd({ stuArtCat }) {
  const { token } = useContext(authContext);
  const [newStuArt, setNewStuArt] = useState({
    author: '',
    title: '',
    titleCN: '',
    category: '',
    imgPreview: '',
    imgFull: '',
    onShelf: true,
    info: '',
    infoCN: '',
  });

  const [imgPreview, setImgPreview] = useState(null);
  const handleImgPreview = (e) => {
    setImgPreview(e.target.files[0]);
  };
  // const [imgFull, setImgFull] = useState(null);
  // const handleImgFull = (e) => {
  //   setImgFull(e.target.files[0]);
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewStuArt((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setNewStuArt((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    const uploadProduct = new FormData();
    uploadProduct.append('imgPrev', imgPreview);
    // uploadProduct.append('imgFull', imgFull);
    // field name 'productImage' must match backend post: upload.single('productImage')

    Object.keys(newStuArt).forEach(function (k) {
      uploadProduct.append(k, newStuArt[k]);
    });

    try {
      await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/stuarts/add',
        uploadProduct,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    setNewStuArt({
      author: '',
      title: '',
      titleCN: '',
      category: '',
      onShelf: true,
      info: '',
      infoCN: '',
    });
    setImgPreview(null);
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className='row mt-2 bg-white'>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>title: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='title'
            value={newStuArt.title}
            onChange={handleChange}
            placeholder='title'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='titleCN'
            value={newStuArt.titleCN}
            onChange={handleChange}
            placeholder='名称'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>author: </span>
          <input
            required
            type='text'
            style={{ width: 80 }}
            name='author'
            value={newStuArt.author}
            onChange={handleChange}
            placeholder='author'
          />
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>category: </span>
          <select
            required
            name='category'
            style={{ width: 100 }}
            defaultValue={''}
            onChange={handleChange}
          >
            <option disabled value=''>
              select category
            </option>
            {stuArtCat.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>

        <div className='col-11 col-lg-2 '>
          <span className='d-lg-none'>imgPreview: </span>
          <input type='file' name='imgPreview' onChange={handleImgPreview} />
        </div>
        {/* <div className='col-11 col-lg-1 '>
          <span className='d-lg-none'>imgFull: </span>
          <input type='file' name='imgFull' onChange={handleImgFull} />
        </div> */}

        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input
            type='checkbox'
            name='onShelf'
            checked={newStuArt.onShelf}
            onChange={handleCheck}
          />
        </div>
        {/* 
        <div className='col-11 col-lg-2'>
          <input
            name='info'
            type='text'
            style={{ width: 200 }}
            value={newStuArt.info}
            onChange={handleChange}
            placeholder='Product Info'
          />
        </div>
        <div className='col-11 col-lg-2'>
          <input
            type='text'
            name='infoCN'
            style={{ width: 200 }}
            value={newStuArt.infoCN}
            onChange={handleChange}
            placeholder='商品信息'
          />
        </div> */}

        <div className='col-11 mx-atuo col-lg-1'>
          <span className='d-lg-none'>Save: </span>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
            <span>+</span>
          </button>
        </div>
      </div>
    </form>
  );
}
