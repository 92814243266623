import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';
// import Header from './components/Header';
import ShopNavbar from './components/ShopNavbar';
import Home from './components/Home/Home';
import AboutUs from './components/About/AboutUs';
import EnrolForm from './components/Enrolment/EnrolForm';
import StuArtGallery from './components/StudentArtworks/StuArtGallery';
import Contact from './components/Contact/Contact';
import PrivateRoute from './components/Routes/PrivateRoute';
import AdminRoute from './components/Routes/AdminRoute';
import AuthProvider from './context/authProvider';
import ProductProvider from './context/productProvider';
import Products from './components/Product/Products';
import ProductSearch from './components/Product/ProductSearch';
import ProductDetails from './components/Product/ProductDetails';
import ProductManage from './components/ProductManage/ProductManage';
import Cart from './components/Cart/Cart';
import OrderCheckout from './components/Order/OrderCheckout';
import MyOrders from './components/Order/MyOrders';
import OrderManage from './components/Order/OrderManage';
import CategoryManage from './components/Category/CategoryManage';
import UserUpdate from './components/User/UserUpdate';
import UserManage from './components/UserManage/UserManage';
import UserActivation from './components/User/UserActivation';
import ResetPassword from './components/User/ResetPassword';
import EnrolSlot from './components/EnrolSlot/EnrolSlot';
import ManageStuArts from './components/StudentArtworks/ManageStuArts';
import Footer from './components/Footer';
import EnrolOrder from './components/EnrolOrder/EnrolOrder';
import MyEnrolOrder from './components/EnrolOrder/MyEnrolOrder';

function App() {
  return (
    <AuthProvider>
      <ProductProvider>
        <Router>
          {/* <Header /> */}
          <ShopNavbar />
          <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path='/aboutus' component={AboutUs} />
            <Route exact path='/enrolform' component={EnrolForm} />

            <Route exact path='/gallery/show' component={StuArtGallery} />
            <Route exact path='/contact' component={Contact} />
            <Route
              exact
              path='/user/activate/:emailToken'
              component={UserActivation}
            />
            <Route
              exact
              path='/user/resetPassword/:emailToken'
              component={ResetPassword}
            />
            <Route exact path='/products/search' component={ProductSearch} />
            <Route exact path='/products/all' component={Products} />
            <Route
              exact
              path='/productbyid/:productId'
              component={ProductDetails}
            />
            <Route exact path='/cart' component={Cart} />
            <PrivateRoute exact path='/user/update' component={UserUpdate} />
            <PrivateRoute
              exact
              path='/cartcheckout'
              component={OrderCheckout}
            />
            <PrivateRoute exact path='/order/myorders' component={MyOrders} />
            <PrivateRoute
              exact
              path='/enrol/myorders'
              component={MyEnrolOrder}
            />

            <AdminRoute
              exact
              path='/product/manage'
              component={ProductManage}
            />
            <AdminRoute exact path='/user/manage' component={UserManage} />
            <AdminRoute exact path='/order/manage' component={OrderManage} />
            <AdminRoute exact path='/enrol/manage' component={EnrolSlot} />
            <AdminRoute exact path='/enrol/orders' component={EnrolOrder} />
            <AdminRoute
              exact
              path='/stuarts/manage'
              component={ManageStuArts}
            />
            <AdminRoute
              exact
              path='/category/manage'
              component={CategoryManage}
            />
            <Redirect to='/' />
          </Switch>
          <Footer />
        </Router>
      </ProductProvider>
    </AuthProvider>
  );
}

export default App;
