import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import authContext from '../context/authContext';

export default function Header() {
  const { langEn } = useContext(authContext);
  return (
    <div className='container text-white p-5'>
      <div className='row d-none d-md-flex'>
        <div className='col-md-2 p-2 text-center'>
          <Link to='/'>
            {
              <img
                src={'/logo_infArt.png'}
                alt='infArt logo'
                width='100'
                // style={{ width: '9rem' }}
              />
            }
          </Link>
        </div>
        <div className='col-md-10 text-center p-1'>
          <h1
            style={{
              fontWeight: '900',
              // fontSize: '3rem',
              letterSpacing: '1px',
            }}
          >
            {langEn ? 'INFINITY ART SCHOOL' : '无限艺术学校'}
          </h1>
          <h5 style={{ fontWeight: '900' }}>
            {langEn ? (
              <span>CREATIVITY &bull; IMAGINATION &bull; INSPIRATION</span>
            ) : (
              <span>创造力 &bull; 想像力 &bull; 灵感</span>
            )}
          </h5>
          <div>
            <span role='img' aria-label='address'>
              <nobr>
                <Link to='/contact' className='text-light'>
                  <span role='img' aria-label='address'>
                    🏫
                  </span>
                  : <u>2/120A Rowe Street, Eastwood</u>
                </Link>
              </nobr>{' '}
              |{' '}
              <nobr>
                <a href='tel:0435090818' className='text-light'>
                  <span role='img' aria-label='phone'>
                    ☎️
                  </span>
                  : <u>0435 090 818</u>
                </a>
              </nobr>{' '}
              |{' '}
              <nobr>
                <a href='mailto:InfinityArtEW@gmail.com' className='text-light'>
                  <span role='img' aria-label='email'>
                    📧
                  </span>
                  : <u>InfinityArtEW@gmail.com</u>
                </a>
              </nobr>
            </span>
          </div>
        </div>
      </div>

      {/* header for mobile phone */}
      <div className='text-center d-md-none position-relative'>
        <Link to='/' className='text-white'>
          {/* </div> */}
          {/* <div className='col-10 text-center'> */}
          <h5
            style={{
              fontWeight: '900',
              // fontSize: '3rem',
              // letterSpacing: '1px',
            }}
          >
            <img
              src={'/logo_infArt.png'}
              alt='infArt logo'
              width='30px'
              // style={{ width: '9rem' }}
            />{' '}
            {langEn ? 'INFINITY ART SCHOOL' : '无限艺术学校'}
          </h5>
        </Link>
        {/* </div> */}
        <div>
          <small>
            {langEn ? (
              <span>CREATIVITY &bull; IMAGINATION &bull; INSPIRATION</span>
            ) : (
              <span>创造力 &bull; 想像力 &bull; 灵感</span>
            )}
          </small>
        </div>
        <div>
          <small style={{ fontSize: 'xx-small' }}>
            <span role='img' aria-label='address'>
              <nobr>
                <Link to='/contact' className='text-light'>
                  <span role='img' aria-label='address'>
                    🏫
                  </span>
                  : <u>2/120A Rowe Street, Eastwood</u>
                </Link>
              </nobr>{' '}
              |{' '}
              <nobr>
                <a href='tel:0435090818' className='text-light'>
                  <span role='img' aria-label='phone'>
                    ☎️
                  </span>
                  : <u>0435 090 818</u>
                </a>
              </nobr>{' '}
              |{' '}
              <nobr>
                <a href='mailto:InfinityArtEW@gmail.com' className='text-light'>
                  <span role='img' aria-label='email'>
                    📧
                  </span>
                  : <u>InfinityArtEW@gmail.com</u>
                </a>
              </nobr>
            </span>
          </small>
        </div>
      </div>
    </div>
  );
}
