import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';

export default function MyEnrolOrderItemModal({ enrolOrder }) {
  const [showModal, setShowModal] = useState(false);
  const {
    // _id,
    student,
    DoB,
    gender,
    parentEmail,
    parentPhone,
    selSlots,
    payAmount,
    payMethod,
    payInfo,
    receipt,
    confirm,
    submitDate,
    payDate,
    note,
  } = enrolOrder;

  return (
    <>
      <button className='btn btn-link' onClick={() => setShowModal(true)}>
        Details
      </button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Invoice #{receipt}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-4'>Student:</div>
            <div className='col-8'>{student}</div>
            <div className='col-4'>DoB/Gender:</div>
            <div className='col-8'>
              {DoB} / {gender}
            </div>

            <div className='col-4'>Email/Phone:</div>
            <div className='col-8'>
              {parentEmail} / {parentPhone}
            </div>

            <div className='col-4'>Class:</div>
            <div className='col-8'>{selSlots.join(', ')}</div>
            <div className='col-4'>Payment:</div>
            <div className='col-8'>
              ${payAmount} by {payMethod}
            </div>
            <div className='col-4'>Info:</div>
            <div className='col-8'>{payInfo}</div>

            <div className='col-4'>Invoice:</div>
            <div className='col-8'>#{receipt}</div>
            <div className='col-4'>Request Date:</div>
            <div className='col-8'>
              {new Date(submitDate).toLocaleDateString()}
            </div>
            <div className='col-4'>Status:</div>
            <div className='col-8'>
              {confirm
                ? 'Paid on: '
                : 'Not paid (please pay at Infinity Art School)'}
              {payDate && new Date(payDate).toLocaleDateString()}
            </div>
            <div className='col-4'>Note:</div>
            <div className='col-8'>{note}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
