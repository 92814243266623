import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';

import './EnrolForm.css';
import Title from '../Title';
import authContext from '../../context/authContext';
import EnrolFormConfirm from './EnrolFormConfirm';
import EnrolFormTermCond from './EnrolFormTermCond';
import EnrolFormUser from './EnrolFormUser';
import EnrolFormStudent from './EnrolFormStudent';
import EnrolFormCalendar from './EnrolFormCalendar';
import EnrolFormSlots from './EnrolFormSlots';
import EnrolFormPayment from './EnrolFormPayment';
import { Spinner } from 'react-bootstrap';

export default function EnrolForm() {
  const { token, langEn } = useContext(authContext);

  const [isLoading, setIsLoading] = useState(false);
  const [enrolSheet, setEnrolSheet] = useState({
    student: '',
    DoB: '',
    gender: '',
    selSlots: [],
    payAmount: '',
    payMethod: '',
    payInfo: '',
  });
  const [enrolUser, setEnrolUser] = useState({
    name: '',
    email: '',
    phone: '',
    address: { line1: '', suburb: '', state: 'NSW', postcode: '' },
  });
  const [enrolSlots, setEnrolSlots] = useState([]);
  const [orderConfirm, setOrderConfirm] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/users/findbytoken`,
          {
            headers: { Authorization: authStr },
          }
        );
        const {
          fname,
          lname,
          email,
          phone,
          address: { line1, line2, suburb, state, postcode },
        } = result.data;
        setEnrolUser({
          name: fname + ' ' + lname,
          email,
          phone,
          address: { line1, line2, suburb, state, postcode },
        });
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    token && fetchUser();
  }, [token]);

  useEffect(() => {
    async function fetchSlot() {
      setIsLoading(true);
      try {
        const res = await Axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/enrolSlots/`
        );
        res &&
          res.data.length > 0 &&
          res.data.sort((a, b) => b.active - a.active || a.slotID - b.slotID);
        setEnrolSlots(res.data.filter((item) => item.active));
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchSlot();
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    const _enrolment = { enrolSheet, enrolUser };
    try {
      const res = await Axios.post(
        process.env.REACT_APP_BACKEND_URL + '/enrolOrders/add',
        _enrolment
      );
      console.log(res.data);
      setOrderConfirm(res.data);
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }

  return (
    <>
      {isLoading ? (
        <>
          <Spinner animation='border' role='loading' />
          Loading...
        </>
      ) : (
        <>
          {orderConfirm ? (
            <EnrolFormConfirm
              orderConfirm={orderConfirm}
              parentName={enrolUser.name}
            />
          ) : (
            <>
              <Title
                bg_img='/img/title_enrol.png'
                title={langEn ? <span>Enrol Form</span> : '注册表格'}
              />

              <div
                className='container p-3 my-3'
                style={{
                  color: 'black',
                  backgroundColor: '#f3f3f3',
                  boxShadow: '1px 1px',
                }}
              >
                <form onSubmit={handleSubmit}>
                  <EnrolFormStudent
                    enrolSheet={enrolSheet}
                    setEnrolSheet={setEnrolSheet}
                  />

                  <EnrolFormUser
                    enrolUser={enrolUser}
                    setEnrolUser={setEnrolUser}
                  />

                  <div className='form-row'>
                    <EnrolFormSlots
                      enrolSlots={enrolSlots}
                      setEnrolSheet={setEnrolSheet}
                    />

                    <EnrolFormPayment
                      enrolSheet={enrolSheet}
                      setEnrolSheet={setEnrolSheet}
                    />

                    <EnrolFormCalendar />
                  </div>
                </form>

                <hr />

                <EnrolFormTermCond />
              </div>
            </>
          )}
        </>
      )}
    </>
  );
}
