import React from 'react';

export default function EnrolFormStudent({ enrolSheet, setEnrolSheet }) {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setEnrolSheet((prevEx) => {
      return {
        ...prevEx,
        [name]: value,
      };
    });
  };

  return (
    <div>
      <div className='form-row'>
        <div className='form-group required col-md-4'>
          <label htmlFor='studentName' className='control-label'>
            Student Name
          </label>
          <input
            type='text'
            required
            className='form-control'
            id='studentName'
            name='student'
            value={enrolSheet.student}
            onChange={handleChange}
          />
        </div>
        <div className='form-group required col-md-4'>
          <label htmlFor='DoB' className='control-label'>
            Date of Birth
          </label>
          <input
            type='date'
            id='DoB'
            required
            className='form-control'
            name='DoB'
            value={enrolSheet.DoB}
            onChange={handleChange}
          />
        </div>
        <div className='form-group required col-md-4'>
          <label className='control-label' htmlFor='gender'>
            Gender
          </label>
          <select
            className='custom-select mr-sm-2'
            id='gender'
            name='gender'
            defaultValue='Boy/Girl'
            onChange={handleChange}
          >
            <option disabled value='Boy/Girl'>
              Boy/Girl
            </option>
            <option value='Boy'>Boy</option>
            <option value='Girl'>Girl</option>
          </select>
        </div>
      </div>
    </div>
  );
}
