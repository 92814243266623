import React, { useState, useEffect } from 'react';
import Axios from 'axios';

import productContext from './productContext';

const ProductProvider = (props) => {
  const [products, setProducts] = useState([]);
  const [onShelfProducts, setOnShelfProducts] = useState([]);
  // product=[{_id,name,cnName,img,brand,cnBrand,price,includeGST,unit,cnUnit,onShelf,stock,shelf_pos}]

  const [categories, setCategories] = useState([]);
  const [subCats, setSubCats] = useState([]);

  const [cart, setCart] = useState([]);
  // cart=[{_id,name,cnName,img,price,includeGST,unit,cnUnit,shelf_pos,quantity}]

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const resProd = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/products'
        );
        // console.log('product: ', resProd);
        resProd &&
          resProd.data.length > 0 &&
          resProd.data.sort(
            (a, b) =>
              b.onShelf - a.onShelf ||
              a.shelf_pos.split('.')[0] - b.shelf_pos.split('.')[0] ||
              a.shelf_pos.split('.')[1] - b.shelf_pos.split('.')[1] ||
              a.shelf_pos.split('.')[2] - b.shelf_pos.split('.')[2]
          );

        setProducts([...resProd.data]);
        setOnShelfProducts([...resProd.data.filter((item) => item.onShelf)]);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const resCat = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/categories'
        );
        resCat &&
          resCat.data.length > 0 &&
          resCat.data.sort((a, b) => a.catID - b.catID);

        setCategories([...resCat.data]);

        const resSubCat = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/subCats'
        );
        setSubCats([...resSubCat.data]);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    // each time products are updated or the user comes back after a long time,
    // the cart contents need to be updated to reflect product changes
    // in price, stock, or onShelf status.
    let updatedCart = [];
    const updateCart = async () => {
      try {
        const _cart = JSON.parse(
          localStorage.getItem(process.env.REACT_APP_LS_CART)
        );

        if (_cart && _cart.length > 0) {
          _cart.forEach((cartItem) => {
            const foundProduct = products.find(
              (_product) => _product._id === cartItem._id
            );
            if (foundProduct) {
              // only add the product if it is still in product list
              const {
                _id,
                name,
                cnName,
                img,
                price,
                includeGST,
                unit,
                cnUnit,
                stock,
                onShelf,
                shelf_pos,
              } = foundProduct;

              if (onShelf && stock > 0) {
                // only add the product if it is still onShelf and have stock
                const newQuantity =
                  stock >= cartItem.quantity ? cartItem.quantity : stock;
                updatedCart.push({
                  _id,
                  name,
                  cnName,
                  img,
                  price,
                  includeGST,
                  unit,
                  cnUnit,
                  shelf_pos,
                  quantity: newQuantity,
                });
              }
            }
          });
        }

        updatedCart.sort(
          (a, b) =>
            a.shelf_pos.split('.')[0] - b.shelf_pos.split('.')[0] ||
            a.shelf_pos.split('.')[1] - b.shelf_pos.split('.')[1] ||
            a.shelf_pos.split('.')[2] - b.shelf_pos.split('.')[2]
        );

        setCart([...updatedCart]);
        localStorage.setItem(
          process.env.REACT_APP_LS_CART,
          JSON.stringify(updatedCart)
        );
      } catch (error) {
        console.error(error);
      }
    };

    if (products.length > 0) {
      updateCart();
    }
  }, [products]);

  const addToCart = (_productId) => {
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex < 0) {
      // this product is not in the cart list, so add a new product to cart
      const productToBeAdded = products.find(({ _id }) => _id === _productId);
      const {
        _id,
        name,
        cnName,
        img,
        price,
        includeGST,
        unit,
        cnUnit,
        shelf_pos,
      } = productToBeAdded;
      _cart.push({
        _id,
        name,
        cnName,
        img,
        price,
        includeGST,
        unit,
        cnUnit,
        shelf_pos,
        quantity: 1,
      });
    } else {
      // productId is already in cart list, add one more.
      _cart[pIndex].quantity++;
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const removeFromCart = (_productId) => {
    // remove one product: quantity--
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex >= 0) {
      if (_cart[pIndex].quantity > 1) {
        _cart[pIndex].quantity--;
      } else {
        _cart.splice(pIndex, 1);
      }
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const deleteProductFromCart = (_productId) => {
    // delete the entire product from cart regardless its quantity
    const _cart = [...cart];
    const pIndex = _cart.findIndex((item) => item._id === _productId);
    if (pIndex >= 0) {
      _cart.splice(pIndex, 1);
    }

    setCart([..._cart]);
    localStorage.setItem(process.env.REACT_APP_LS_CART, JSON.stringify(_cart));
  };

  const clearCart = () => {
    setCart([]);
    localStorage.removeItem(process.env.REACT_APP_LS_CART);
  };

  return (
    <productContext.Provider
      value={{
        cart,
        catList: categories,
        subCats,
        products,
        onShelfProducts,
        categories,
        isLoading,
        addToCart,
        removeFromCart,
        deleteProductFromCart,
        clearCart,
      }}
    >
      {props.children}
    </productContext.Provider>
  );
};

export default ProductProvider;
