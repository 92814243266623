/* eslint-disable no-lone-blocks */
import React, { useState, useEffect, useContext } from 'react';
import Axios from 'axios';

import Title from '../Title';
import OrderItem from './OrderItem';
import authContext from '../../context/authContext';

function MyOrders() {
  const { token, langEn } = useContext(authContext);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await Axios.get(
          process.env.REACT_APP_BACKEND_URL + '/orders/bytoken',
          {
            headers: { Authorization: authStr },
          }
        );

        setOrders([...result.data]);
      } catch (error) {
        console.error(error);
      }
    };

    if (!!token) {
      fetchOrders();
    }
  }, [token]);

  return (
    <React.Fragment>
      <Title title={langEn ? 'My Orders' : '我的订单'} />
      <div className='container-fluid mt-3'>
        <div className='row'>
          {orders.map((item) => {
            return (
              <OrderItem
                key={item._id}
                orderId={item._id}
                paidDate={item.paidDate}
                paymentId={item.paymentId}
                deliveredDate={item.deliveredDate}
                productList={item.productList}
              />
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
}

export default MyOrders;
