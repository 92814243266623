import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';

import productContext from '../../context/productContext';
import authContext from '../../context/authContext';
import Title from '../Title';
import OrderItem from './OrderItem';
import './Order.css';
import UserAddressModal from '../User/UserAddressModal';

export default function OrderCheckoutForm() {
  const { token, langEn } = useContext(authContext);
  const { cart, clearCart } = useContext(productContext);
  const [isProcessing, setProcessingTo] = useState(false);
  const [checkoutError, setCheckoutError] = useState();
  const [confirmedOrder, setConfirmedOrder] = useState();

  const [owner, setOwner] = useState({
    fname: '',
    lname: '',
    email: '',
    admin: '',
    phone: '',
    address: { line1: '', suburb: '', state: '', postcode: '' },
  });

  useEffect(() => {
    const fetchOwner = async () => {
      const authStr = 'Bearer ' + token;
      try {
        const result = await axios.get(
          process.env.REACT_APP_BACKEND_URL + '/users/findbytoken',
          {
            headers: { Authorization: authStr },
          }
        );
        setOwner(result.data);
      } catch (error) {
        console.error(error);
      }
    };
    if (token) {
      fetchOwner();
    }
  }, [token]);

  const amount = cart.reduce(
    (cost, item) => cost + item.price * item.quantity,
    0
  );

  const stripe = useStripe();
  const elements = useElements();

  const stripeSubmit = async (event) => {
    event.preventDefault();
    const authStr = 'Bearer ' + token;

    const billingDetails = {
      name: owner.fname + owner.lname,
      email: owner.email,
      phone: owner.phone,
      address: {
        line1: owner.address.line1,
        city: owner.address.suburb,
        state: owner.address.state,
        postal_code: owner.address.postcode,
        country: 'AU',
      },
    };

    setProcessingTo(true);

    const cardElement = elements.getElement('card');

    try {
      const { data: client_secret } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/stripepay',
        cart,
        {
          headers: { Authorization: authStr },
        }
      );

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        return;
      }

      const paymentConfirm = await stripe.confirmCardPayment(client_secret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      });

      if (paymentConfirm.error) {
        setCheckoutError(paymentConfirm.error.message);
        setProcessingTo(false);
        return;
      }

      // console.log('SUCCESS!', paymentConfirm.paymentIntent.payment_method);
      const { data: confirmedOrder } = await axios.post(
        process.env.REACT_APP_BACKEND_URL + '/orders/add',
        { cart, payment_method: paymentConfirm.paymentIntent.payment_method },
        {
          headers: { Authorization: authStr },
        }
      );
      setConfirmedOrder(confirmedOrder);
      clearCart();
      setProcessingTo(false);
    } catch (error) {
      setCheckoutError(error.message);
    }
  };

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const iframeStyles = {
    base: {
      color: '#fff',
      // backgroundColor: '#6772e5',
      fontSize: '16px',
      iconColor: '#fff',
      '::placeholder': {
        color: '#87bbfd',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
    complete: {
      iconColor: '#cbf4c9',
    },
  };

  const cardElementOpts = {
    iconStyle: 'solid',
    style: iframeStyles,
    padding: '15px',
    hidePostalCode: true,
  };

  function updateOwner(updateUser) {
    setOwner(updateUser);
  }

  return (
    <>
      {!confirmedOrder && (
        <>
          <Title title={langEn ? 'Payment' : '支付'} />
          <div className='container-fluid row'>
            <OrderItem
              title={<h5>{langEn ? 'Cart Summary' : '购物车内容：'}</h5>}
              productList={cart}
            />

            <div className='card card-stripe m-2'>
              <div className='card-header' style={{ color: '#fff' }}>
                <h5>{langEn ? 'Payment Details:' : '支付细节：'}</h5>
              </div>

              <div>
                <div className='card-stripe-element'>
                  <div className='row'>
                    <div className='col-4'>Name:</div>
                    <div className='col-8'>
                      {owner.fname + ' ' + owner.lname}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>Email:</div>
                    <div className='col-8'>{owner.email}</div>
                  </div>
                  <div className='row'>
                    <div className='col-4'>Deliver to:</div>
                    <div className='col-8'>{owner.address.line1}</div>
                  </div>{' '}
                  <div className='row'>
                    <div className='col-4'>
                      <UserAddressModal
                        user={owner}
                        updateOwner={updateOwner}
                      />
                    </div>
                    <div className='col-8'>
                      {owner.address.suburb}, {owner.address.state}{' '}
                      {owner.address.postcode}
                    </div>
                  </div>
                </div>

                <form onSubmit={stripeSubmit}>
                  <div className='card-stripe-element'>
                    <CardElement
                      options={cardElementOpts}
                      onChange={handleCardDetailsChange}
                    />
                    <div>
                      *** Test payment only!{' '}
                      <span style={{ color: 'red' }}>DON'T</span> use your
                      credit card ***
                    </div>
                    <div className='my-2'>
                      <a
                        href='https://stripe.com/docs/currencies'
                        target='_blank'
                        rel='noopener noreferrer'
                        title='Users in Australia can accept Visa Mastercard American Express JCB credit and debit cards.'
                      >
                        <img
                          src={'/img/cc_visa.png'}
                          alt='visa'
                          style={{ height: '20px' }}
                        />{' '}
                        <img
                          src={'/img/cc_master.png'}
                          alt='master'
                          style={{ height: '20px' }}
                        />{' '}
                        <img
                          src={'/img/cc_american.png'}
                          alt='american'
                          style={{ height: '20px' }}
                        />{' '}
                        <img
                          src={'/img/cc_jcb.png'}
                          alt='jcb'
                          style={{ height: '20px' }}
                        />
                        <span style={{ color: '#87bbfd' }}>
                          {' '}
                          Accept major credict/debit cards
                        </span>
                      </a>
                    </div>
                  </div>

                  <div className='card-stripe-element'>
                    <div className='my-1'>
                      {checkoutError && <h3>{checkoutError}</h3>}
                      <div className='my-1 text-center'>
                        <button
                          className='btn btn-light btn-stripe'
                          type='submit'
                          disabled={isProcessing || !stripe}
                          title='Your payment is secured by stripe.com'
                        >
                          <i className='fas fa-lock'></i>
                          {isProcessing
                            ? ' Processing... '
                            : ` Pay*: $${amount.toFixed(2)} `}
                          powered by{' '}
                          <img
                            src='/img/stripe_btn_logo.png'
                            alt='stripe'
                            style={{ height: '20px' }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div className='card-footer text-center'>
                <Link to='/cart'>
                  <button className='btn btn-outline-light btn-primary m-2'>
                    <i className='fas fa-shopping-cart'></i>
                    {langEn ? 'Back to Cart' : '回到购物车'}
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}

      {confirmedOrder && (
        <>
          <Title title={langEn ? 'Payment Confirmation' : '支付确认'} />
          <OrderItem
            orderId={confirmedOrder._id}
            paymentId={confirmedOrder.paymentId}
            paidDate={confirmedOrder.paidDate}
            productList={confirmedOrder.productList}
          />

          <Link to='/'>
            <button className='btn btn-outline-light btn-primary m-2'>
              <i className='fas fa-store'></i>
              {langEn ? 'Back to Shop' : '回到商店'}
            </button>
          </Link>
        </>
      )}
    </>
  );
}
