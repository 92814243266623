import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import productContext from '../../context/productContext';
import authContext from '../../context/authContext';
import './Product.css';

function ProductItem({ product }) {
  const { cart, addToCart, removeFromCart } = useContext(productContext);
  const { langEn } = useContext(authContext);
  const [qtyInCart, setQtyInCart] = useState(0);

  const {
    _id,
    name,
    cnName,
    img,
    price,
    stock,
    unit,
    cnUnit,
    shelf_pos,
    info,
  } = product;

  useEffect(() => {
    const _itemInCart = cart.find((item) => item._id === _id);
    if (_itemInCart) {
      setQtyInCart(_itemInCart.quantity);
    } else {
      setQtyInCart(0);
    }
  }, [_id, cart, qtyInCart]);

  return (
    <div className='col-6 col-md-4 col-lg-2 p-1 d-flex align-items-stretch'>
      <div className='card'>
        <span className='card-shelf-text text-muted'>{shelf_pos}</span>
        <div className='img-container p-3'>
          {info.split(':')[0] === 'SFMBox' ? (
            // Show SFMBox, where boxID is embedded in product.info
            // SFMBox product has info: 'SFMBox:0345579444'
            <Link to={`/SFMBoxes/byId/${info.split(':')[1]}`}>
              <img
                src={process.env.REACT_APP_ASSET_URL + '/' + img}
                alt={name}
                className='card-img-top'
              />
            </Link>
          ) : (
            // not SFMBox: show normal product
            <Link to={`/productbyid/${_id}`}>
              {' '}
              <img
                src={process.env.REACT_APP_ASSET_URL + '/' + img}
                alt={name}
                className='card-img-top'
              />
            </Link>
          )}
        </div>
        <div className='card-footer'>
          <p className='text-left mb-0'>{langEn ? name : cnName}</p>${price}/
          {langEn ? unit : cnUnit}
          {qtyInCart > 0 && (
            <>
              <span className='card-btn-qty'>{qtyInCart}</span>
              <button
                className='btn shadow-none card-btn-minus'
                onClick={() => removeFromCart(_id)}
              >
                {/* <strong>-</strong> */}
                <i className='fas fa-minus'></i>
              </button>
            </>
          )}
          {stock > 0 ? (
            <button
              className='btn card-btn-add shadow-none'
              title={langEn ? 'buy' : '买'}
              onClick={() => addToCart(_id)}
            >
              {/* <strong>+</strong> */}
              {/* <i className='fas fa-plus'></i> */}
              <i className='fas fa-cart-plus'></i>
            </button>
          ) : langEn ? (
            <button className='card-btn-out' disabled>
              Out of Stock
            </button>
          ) : (
            <button className='card-btn-out' disabled>
              已售罄
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductItem;
