import React, { useState, useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';
// import productContext from '../../context/productContext';

export default function ProductManageEdit({ index, stuArt }) {
  // const { catList, subCats } = useContext(productContext);

  const {
    _id,
    author,
    title,
    titleCN,
    category,
    imgPreview,
    onShelf,
    info,
    infoCN,
  } = stuArt;

  const { token } = useContext(authContext);
  const [editStuArt, setEditStuArt] = useState({
    author,
    title,
    titleCN,
    category,
    imgPreview,
    onShelf,
    info,
    infoCN,
  });

  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setEditStuArt((prevEx) => {
  //     return {
  //       ...prevEx,
  //       [name]: value,
  //     };
  //   });
  // };

  const handleCheck = (event) => {
    const { name, checked } = event.target;
    setEditStuArt((prevEx) => {
      return {
        ...prevEx,
        [name]: checked,
      };
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const authStr = 'Bearer ' + token;

    try {
      await axios.patch(
        process.env.REACT_APP_BACKEND_URL + '/stuarts/update/' + _id,
        editStuArt,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    // Clear form
    window.location.reload(false);
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className={index % 2 ? 'row mt-2 bg-white' : 'row mt-2'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>title: </span>
          {title}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>名称: </span>
          {titleCN}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>author: </span>
          {author}
        </div>
        <div className='col-11 col-lg-1 text-left'>
          <span className='d-lg-none'>category: </span>
          {category}
        </div>
        <div className='col-11 col-lg-2 text-center'>
          <img
            src={process.env.REACT_APP_ASSET_URL + '/' + imgPreview}
            style={{ width: '3rem', height: '2rem' }}
            className='img-fluid'
            alt='stuArt'
          />
        </div>

        <div className='col-11 col-lg-1 text-center'>
          <span className='d-lg-none'>onShelf: </span>
          <input
            type='checkbox'
            name='onShelf'
            checked={editStuArt.onShelf}
            onChange={handleCheck}
          />
        </div>
        {/* 
        <div className='col-11 col-lg-2'>
          <input
            name='info'
            type='text'
            style={{ width: 200 }}
            value={editStuArt.info}
            onChange={handleChange}
            placeholder='Product Information'
          />
        </div>
        <div className='col-11 col-lg-2'>
          <input
            type='text'
            name='infoCN'
            style={{ width: 200 }}
            value={editStuArt.infoCN}
            onChange={handleChange}
            placeholder='商品信息'
          />
        </div> */}

        <div className='col-11 col-lg-1'>
          <button className='btn btn-link' type='submit'>
            <i className='fas fa-save' />
          </button>
        </div>
      </div>
    </form>
  );
}
