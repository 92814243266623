import React, { useContext } from 'react';

import Title from '../Title';
import ProductItem from './ProductItem';
import productContext from '../../context/productContext';
import authContext from '../../context/authContext';

export default function Products() {
  const { products, catList } = useContext(productContext);
  const { langEn } = useContext(authContext);

  return (
    <>
      <Title bg_img='/img/title_shop.png' title={langEn ? 'Shop' : '商店'} />
      {catList.map((cat, index) => {
        return (
          <div key={index} className='container-fluid'>
            <h3 className='text-light text-center text-dark'>
              {langEn ? cat.name : cat.nameCN}
            </h3>
            <div className='row text-dark px-1'>
              {products
                .filter((item) => item.categoryId._id === cat._id)
                .map((product) => {
                  return <ProductItem key={product._id} product={product} />;
                })}
            </div>
          </div>
        );
      })}
    </>
  );
}
