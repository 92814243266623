import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';

import Title from '../Title';
import authContext from '../../context/authContext';
import Header from '../Header';
import bg_about from './bg_about.jpg';

export default function AboutUs() {
  const { langEn } = useContext(authContext);

  return (
    <>
      <Title
        bg_img='/img/title_about.png'
        title={langEn ? <span>ABOUT US</span> : '关于我们'}
      />

      <div
        className='text-light'
        style={{ backgroundImage: `url(${bg_about})` }}
      >
        <Header />

        <section className='bg-white text-dark'>
          <div className='container py-3'>
            <div className='row'>
              {/* <div className='col-12'>
              {langEn ? (
                <h3>CREATIVITY &bull; IMAGINATION &bull; INSPIRATION</h3>
              ) : (
                <h3>创造力 &bull; 想像力 &bull; 灵感</h3>
              )}
            </div> */}
              <div className='col-md-6'>
                {langEn ? (
                  <>
                    <p>
                      Our Art school strives for excellence and specialises in
                      oil painting, pencil sketch, watercolour, oil pastel,
                      colour pencil and handcraft. We cater for students from
                      age 5 to 18.
                    </p>
                    <p>
                      We established our Eastwood School in July 2016. We pride
                      ourselves to provide one on one approach with our students
                      to ensure their development is at its full potential. The
                      classroom is well-lit and well ventilated. It only takes 2
                      minutes’ walk from Eastwood train station.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      我们的艺术学校追求卓越，并致力于满足5至18岁学生的油画，铅笔素描，水彩，油画棒，彩色铅笔和手工艺品。
                    </p>
                    <p>
                      我们于2016年7月成立了伊斯特伍德学校。我们很自豪为学生提供一对一的方法，以确保他们的发展得到充分发挥。教室光线充足，通风良好。从伊斯特伍德火车站步行仅需2分钟。
                    </p>
                  </>
                )}
              </div>
              <div className='col-md-6'>
                <img src='/logo_infArt.png' alt='infArt logo' width='35%' />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='container py-3'>
            <div className='row'>
              <div className='col-12'>
                {langEn ? (
                  <h3>How does our school look like?</h3>
                ) : (
                  <h3>我们学校怎么样？</h3>
                )}
              </div>
              <div className='col-md-6'>
                <Card className='bg-dark text-white'>
                  <Card.Img src='/img/about_classroom1.jpg' alt='Card image' />
                  <Card.ImgOverlay>
                    <Card.Title>
                      <h3>{langEn ? 'Classroom' : '教室'}</h3>
                    </Card.Title>
                  </Card.ImgOverlay>
                </Card>
              </div>
              <div className='col-md-6'>
                {langEn ? (
                  <>
                    <p>
                      Our principal has many years teaching experiences in art
                      education field. Her major was fine art and she has more
                      than 20 years drawing experiences. Furthermore, due to the
                      nice personality, patience and great skills, she is very
                      popular among the students.
                    </p>
                    <p>
                      Our programs integrate both interesting and instructive.
                      They provide the fundamental knowledge of aesthetics, like
                      colour perception, shape, shadowing and etc. By following
                      the program, our students could constantly make
                      improvements of their artistic feeling and drawing skills.
                    </p>
                    <p>
                      We understand the importance of skills, imagination and
                      creativity. Hence,import Header from '../Header'; in our
                      school, we not only attentively design our programs for
                      students with different levels of skills but also
                      encourage our students to bring their own ideas of picture
                      to the class and then teach them how to finish these
                      pictures. During the process of drawing their own
                      pictures, it has greatly boosted our students’ interesting
                      in art learning and we all know interest is the best
                      teacher. Furthermore, it is helpful to identify what kind
                      of skill they lack of so we can put more training on that.
                      Practice makes perfect. We can see the increasing of our
                      students’ confidence and happiness after they finish their
                      own work under the guidance of our teacher.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      我们的校长在艺术教育领域有多年的教学经验。她的专业是美术，她有20多年的绘画经验。此外，由于其良好的个性，耐心和出色的技能，她在学生中非常受欢迎。
                    </p>
                    <p>
                      我们的程序融合了有趣和启发性。他们提供美学的基本知识，例如色彩感知，形状，阴影等。通过遵循该计划，我们的学生可以不断提高他们的艺术感和绘画技巧。
                    </p>
                    <p>
                      我们了解技能，想象力和创造力的重要性。因此，在我们学校，我们不仅为不同技能水平的学生专心设计课程，而且还鼓励我们的学生将自己的图画观念带给全班，然后教他们如何完成这些图画。在绘制自己的图片的过程中，它极大地激发了我们的学生对艺术学习的兴趣，我们都知道兴趣是最好的老师。此外，确定他们缺乏的技能是有帮助的，因此我们可以对此进行更多的培训。实践使完美。在老师的指导下完成自己的工作后，我们可以看到学生的自信和幸福感在增加。
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>

        <section className='bg-white text-dark'>
          <div className='container py-3'>
            <div className='row'>
              <div className='col-12'>
                {langEn ? (
                  <h3>
                    Would you like to see some artworks completed by our
                    students?.
                  </h3>
                ) : (
                  <h3>您想看看我们的学生完成的一些艺术品吗？</h3>
                )}
              </div>
              <div className='col-md-6'>
                {langEn ? (
                  <>
                    <p>
                      In addition, there are many data overwhelmingly support
                      that learning and participation in the fine art is a core
                      element in improving all academic areas. It brings
                      benefits to different aspects such as improving dignity,
                      enhancing creativity and productivity, developing better
                      team players and be more prepared when you face the
                      workplace.
                    </p>
                    <p>
                      Overall, there is a universal need for art and our school
                      provide high quality art education. A visiting to the
                      school is always welcome and if you can’t make it during
                      the business time just contact us to book a time suits
                      you.
                    </p>
                  </>
                ) : (
                  <>
                    <p>
                      此外，许多数据压倒性地证明，学习和参与美术是改善所有学术领域的核心要素。它为不同方面带来了好处，例如提高了尊严，提高了创造力和生产力，培养了更好的团队合作者，并且在面对工作场所时做好了充分的准备。
                    </p>
                    <p>
                      总体而言，艺术存在着普遍需求，我们学校提供高质量的艺术教育。总是欢迎您来学校参观，如果您在办公时间内无法访问学校，请与我们联系以预订适合您的时间。
                    </p>
                  </>
                )}
              </div>
              <div className='col-md-6'>
                <Card className='bg-dark text-white'>
                  <Link to='/gallery/show' className='text-light'>
                    <Card.Img
                      src='/img/about_classroom2.jpg'
                      alt='Card image'
                    />
                    <Card.ImgOverlay>
                      <Card.Title>
                        <h3>
                          {langEn ? 'Student Art Gallery' : '学生艺术品展览'}
                        </h3>
                      </Card.Title>
                    </Card.ImgOverlay>
                  </Link>
                </Card>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className='container py-3'>
            <ul>
              <li>
                {' '}
                <Link to='/contact'>
                  {langEn ? (
                    <span>Here are the address and contact details.</span>
                  ) : (
                    <span>这是地址和联系方式。</span>
                  )}
                </Link>
              </li>
              <li>
                <Link to='/enrolform'>
                  {langEn ? (
                    <span>
                      You could kindly enrol online or download the enrolment
                      form.
                    </span>
                  ) : (
                    <span>您可以在线注册或下载注册表格。</span>
                  )}
                </Link>
              </li>
              <li>
                <Link to='/contact'>
                  {langEn ? (
                    <span>
                      If you have any question or suggestion, please feel free
                      to contact us.
                    </span>
                  ) : (
                    <span>如果您有任何问题或建议，请随时与我们联系。</span>
                  )}
                </Link>
              </li>
            </ul>
            {langEn ? (
              <h3>Infinity Art School wish you have a very lovely day.</h3>
            ) : (
              <h3>无限艺术学校祝您有个美好的一天。</h3>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
