import React, { useContext } from 'react';
import axios from 'axios';

import authContext from '../../context/authContext';

export default function EnrolSlotItem({ index, enrolSlot, setEdit }) {
  const { _id, slotID, slotName, active } = enrolSlot;
  const { token } = useContext(authContext);

  async function deleteSlot(productId) {
    const authStr = 'Bearer ' + token;
    // const uploadProduct = new FormData();
    // uploadProduct.append('productImage', productImg);
    // // field name 'productImage' must match backend post: upload.single('productImage')

    // Object.keys(editProduct).forEach(function (k) {
    //   uploadProduct.append(k, editProduct[k]);
    // });

    try {
      await axios.delete(
        process.env.REACT_APP_BACKEND_URL + '/enrolSlots/delete/' + _id,
        {
          headers: { Authorization: authStr },
        }
      );
    } catch (error) {
      console.error(error);
    }

    window.location.reload(false);
  }

  return (
    <>
      <div className={index % 2 ? 'row mt-1 bg-white' : 'row mt-1'}>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>slotID: </span>
          {slotID}
        </div>
        <div className='col-11 col-lg-3'>
          <span className='d-lg-none'>slotName: </span>
          {slotName}
        </div>
        <div className='col-11 col-lg-1'>
          <span className='d-lg-none'>active: </span>
          <input type='checkbox' checked={active} disabled />
        </div>

        <div className='col-11 col-lg-2'>
          <button className='btn btn-sm btn-link' onClick={() => setEdit(_id)}>
            <i className='fas fa-edit' />
          </button>
          <button
            className='btn btn-sm btn-link'
            onClick={() => deleteSlot(_id)}
            disabled={active}
          >
            <i className='fas fa-trash-alt' />
          </button>
        </div>
      </div>
    </>
  );
}
