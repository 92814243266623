import React from 'react';

export default function EnrolFormSlots({ enrolSlots, setEnrolSheet }) {
  function handleSelectSlot(event) {
    const { checked, value } = event.target;
    setEnrolSheet((prevState) => {
      let _selSlots = [...prevState.selSlots];
      if (checked) {
        _selSlots.push(value);
      } else {
        _selSlots = _selSlots.filter((item) => item !== value);
      }
      // let _pay = 0;
      // if (_selSlots.length > 1) {
      //   _pay = _selSlots.length * 250;
      // } else {
      //   _pay = _selSlots.length * 280;
      // }
      return {
        ...prevState,
        selSlots: _selSlots,
        // payAmount: _pay,
      };
    });
  }

  return (
    <fieldset className='form-group required col-md-4'>
      <legend className='col-form-label control-label'>
        <b>Choose Classes to Enrol</b>
      </legend>
      {enrolSlots.map((slot) => {
        return (
          <div key={slot.slotID}>
            <input
              type='checkbox'
              name='selSlot'
              value={slot.slotName}
              onChange={handleSelectSlot}
            />{' '}
            {slot.slotName}
            <br />
          </div>
        );
      })}
    </fieldset>
  );
}
